/**
 * Created by gantushig on 11/6/15.
 */

import {Link} from "react-router-dom";
import React from "react";

const CompanyViewHeader = ({tab, companyId}) => {

    return (
        <div className="mb-5">
            <div className="tabs is-toggle is-fullwidth is-medium">
                <ul>
                    <li className={tab === 0  ? 'is-active' : ''}>
                        <Link to={`/company/${companyId}`}>
                            Байгууллага
                        </Link>
                    </li>
                    <li className={tab === 1  ? 'is-active' : ''}>
                        <Link to={`/company/${companyId}/contacts`}>
                           Хариуцсан ажилтан
                        </Link>
                    </li>
                    <li className={tab === 2  ? 'is-active' : ''}>
                        <Link to={`/company/${companyId}/gcps`}>
                            GCP
                        </Link>
                    </li>
                    <li className={tab === 3  ? 'is-active' : ''}>
                        <Link to={`/company/${companyId}/products`}>
                            Бүтээгдэхүүн
                        </Link>
                    </li>
                </ul>
            </div>
        </div>
    )
}

export default CompanyViewHeader;


