/**
 * Created by gantushig on 11/6/15.
 */


import {Link} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import React, {useEffect, useReducer} from "react";
import {fetchAimaks, fetchCompanies, fetchSectorsAll, printCompanies, resetCompaniesSearch,} from "./actions/actions";
import ReactPaginate from "react-paginate";
import dayjs from "dayjs";
import dayjsPluginUTC from "dayjs-plugin-utc";
import "react-datepicker/dist/react-datepicker.css";
import {ActionTypes, TOKEN} from "./Constants";
import classnames from "classnames";
import {css} from "@emotion/react";
import ClipLoader from "react-spinners/ClipLoader";


dayjs.extend(dayjsPluginUTC)


const initialState = {
    toggle:false,
    companies: [],
    aimaks: [],
    //page: localStorage.getItem("page") || 0,
    pagesize: 25,
    totalPages:0,
    totalElements:0,
    loading: false,

        field:"",
        asc:true
};

const reducer = (state, action) => {
    switch (action.type) {

        case ActionTypes.LOAD_COMPANIES_REQUEST:
            return {
                ...state,
                loading: true
            }
        case ActionTypes.LOAD_COMPANIES_SUCCESS:
            return {
                ...state,
                loading: false,
                companies: action.companies,
                totalElements: action.meta.totalElements,
                totalPages: action.meta.totalPages,
            }
        case 'CHANGE_ORDER': {
            let d = state.asc;
            let f = state.field;
            if (action.field === f) {
                d = !state.asc;
            } else {
                f = action.field;
                d = true
            }

            return {
                ...state,
                asc: d,
                field:f
            };
        }
        case 'TOGGLE':
            //localStorage.setItem("page", "0");
            return {
                ...state,
                toggle: !state.toggle
            };
        case ActionTypes.FETCH_AIMAKS_REQUEST:
            return {
                ...state,
                loading: true
            }
        case ActionTypes.FETCH_AIMAKS_SUCCESS: {
            return {
                ...state,
                aimaks: action.aimaks,
                loading: false
            }
        }

        default:
            return state;
    }
};

const changePage = (page) => {
    return {
        type: 'COMPANY_SEARCH_CHANGE_PAGE',
        page
    }
};

const activateSearch = () => {
    return {
        type: 'TOGGLE'
    }
};

const changeOrder = (field) => {
    return {
        type: 'CHANGE_ORDER',
        field
    }
};

const changeField = (field, payload) => {
    return {
        type: 'COMPANY_SEARCH_CHANGE_FIELD',
        field,
        payload
    }
};


const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;



const CompaniesPage = ({match: {params: {status}}}) => {

    const [state, dispatch] = useReducer(reducer, initialState);
    const {companies,aimaks,
         pagesize, field, asc, toggle
    } = state;

    const reduxDispatch = useDispatch();
    let token = localStorage.getItem(TOKEN) || null;


    const ssectors = useSelector(state => state.sectors);
    let sectors = ssectors.sectors;

    const companySearch = useSelector(state => state.companySearch);
    const {
        page,
        name,
        regnumber,
        phone,
        contact_email,
        email,
        aimak,
        district,
        sector,
        joinedYear,
        joinedMonth,
        isExported,
        isCertificate,
        isGepir
    } = companySearch;

    useEffect(() => {
        console.log('useEffect called');
        reduxDispatch(fetchSectorsAll(token))
    }, []);

    useEffect(() => {
        fetchCompanies(dispatch, token,
            name,
            phone, contact_email, email, regnumber, status,
            aimak, district, sector, joinedYear, joinedMonth,
            isExported, isCertificate, isGepir,
            page, pagesize, field, asc);
    }, [status, field, asc, page, toggle]);

    useEffect(() => {
        fetchAimaks(dispatch);
    }, []);

    const handlePageClick = (data) => {

        reduxDispatch(changePage(data.selected));

    };

    const handleChangeField = (e) => {
        reduxDispatch(changeField(e.target.name, e.target.value));
    };
    const handleChangeCheckField = (e) => {
        reduxDispatch(changeField(e.target.name, e.target.checked));
    };

    const handleSearch = (e) => {
        e.preventDefault();
        reduxDispatch(changePage(0));
        dispatch(activateSearch());
    };

    const handleExcel = (e) => {
        e.preventDefault();
        reduxDispatch(printCompanies(token, name, phone, email, regnumber, status, aimak, district, sector, joinedYear, joinedMonth,
            isExported, isCertificate, isGepir,  field, asc));
    };


    const handleReset = (e) => {
        e.preventDefault();
        reduxDispatch(resetCompaniesSearch());
    };

    const handleOrderClick = (f) => {
        dispatch(changeOrder(f));
    }

    const getStatusLabel = status => {
        let ret = "";
        if (status.toUpperCase() === "ACTIVE") {
            ret = "ИДЭВХТЭЙ";
        } else if (status.toUpperCase() === "REMOVED") {
            ret = "ХАСАГДСАН";
        } else if (status.toUpperCase() === "TEMP_CLOSED") {
            ret = "ТҮР ХААГДСАН";
        }

        return ret
    }

    const getAddress = (p) =>{
        let ret = "";
        let ra = aimaks.filter(b=>b.id === p.aimak);
        if (ra.length>0) {
            ret += ra[0].name +", ";
            let sa  = ra[0].units.filter(c=>c.id === p.district);
            if (sa.length > 0) {
                ret += sa[0].name+", ";
            }

        }
        if (p.soum) {
            ret += p.soum +", ";
        }
        ret += p.address;

        return ret;
    }

    let c = 0;
    const appts2 = companies.map(p => {
        c++;
        let c1 = page * pagesize + c;

        return (
            <tr key={p.id}>
                <td>
                    <Link to={`/company/${p.id}`}>
                        {c1}
                    </Link>
                </td>
                <td>
                    <Link  to={`/company/${p.id}`}>
                        {p.name}
                    </Link>
                </td>
                <td>{p.joined && dayjs.utc(p.joined).format("MM/DD/YYYY")}</td>
                <td>{p.registration_number}</td>
                <td>{p.phone}</td>
                <td>{p.email}</td>
                <td>{p.zipcode}</td>
                <td>{getAddress(p)}</td>
                <td><label className="checkbox"><input type="checkbox" checked={p.is_certificate}
                                                       readOnly={true}/></label></td>

            </tr>
        )
    });

    return (

        <div className="inner-container">

            <div className="level">
                <div className="level-left" style={{paddingTop: '10px'}}>
                    <Link to={`/company-create`} className="button bg-blue">
                        <span style={{color: "white"}}> Шинэ Байгууллага Үүсгэх</span>
                    </Link>
                </div>
            </div>
            <CompaniesSearchPanel
                sectors={sectors}
                aimaks={aimaks}
                name={name}                phone={phone}
                contact_email={contact_email} mail={email}                registry={regnumber}
                aimak={aimak}                district={district}                joinedYear={joinedYear}                joinedMonth={joinedMonth}
                sector={sector}
                isExported={isExported}                isCertificate={isCertificate}
                isGepir={isGepir}
                onChangeField={handleChangeField} onChangeCheckField={handleChangeCheckField}
                onClickSearch={handleSearch} onClickExcel={handleExcel} onClickReset={handleReset}
            />

            <br/>
            <div>
                <h5 style={{marginBottom: "10px"}}><strong>Нийт: {getStatusLabel(status)} {state.totalElements}</strong>
                </h5>

                <nav className="pagination">
                    <ReactPaginate previousLabel={"Өмнөх"}
                                   nextLabel={"Дараах"}
                                   initialPage={page}
                                   breakLabel={"..."}
                                   breakClassName={"pagination-ellipsis"}
                                   pageCount={state.totalPages}
                                   marginPagesDisplayed={2}
                                   pageRangeDisplayed={5}
                                   activeLinkClassName={"is-current"}
                                   pageLinkClassName={"pagination-link"}
                                   onPageChange={(e) => handlePageClick(e)}
                                   previousClassName={"pagination-previous"}
                                   nextClassName={"pagination-next"}
                                   containerClassName={"pagination-list"}
                                   forcePage={page}
                    />
                </nav>


            </div>
            <br/>
            <ClipLoader color="#ffffff" loading={state.loading} css={override} size={150}/>
            <table className="table is-striped is-fullwidth">
                <thead>
                <tr>
                    <th><a onClick={() => handleOrderClick("id")}>#</a></th>
                    <th><a onClick={() => handleOrderClick("name")}>Нэр</a></th>
                    {/*<th><a onClick={() => handleOrderClick("gcps")}>GCP</a></th>*/}
                    <th><a onClick={() => handleOrderClick("joined")}>Элссэн</a></th>
                    <th><a onClick={() => handleOrderClick("registration_number")}>Регистр</a></th>
                    <th>Утас</th>
                    <th>Емэйл</th>
                    <th>Zip</th>
                    <th>Хаяг</th>
                    <th>Cert</th>


                </tr>
                </thead>
                <tbody>
                {appts2}
                </tbody>
            </table>

            <nav className="pagination">
                <ReactPaginate previousLabel={"Өмнөх"}
                               nextLabel={"Дараах"}
                               initialPage={page}
                               breakLabel={"..."}
                               breakClassName={"pagination-ellipsis"}
                               pageCount={state.totalPages}
                               marginPagesDisplayed={2}
                               pageRangeDisplayed={5}
                               activeLinkClassName={"is-current"}
                               pageLinkClassName={"pagination-link"}
                               onPageChange={(e) => handlePageClick(e)}
                               previousClassName={"pagination-previous"}
                               nextClassName={"pagination-next"}
                               containerClassName={"pagination-list"}
                               forcePage={page}
                />
            </nav>
            <br/><br/>
        </div>
    )

}

const CompaniesSearchPanel = ({
                                  sectors, aimaks,
                                  name, phone,  contact_email, email, regnumber, aimak, district, joinedYear, joinedMonth, sector, isExported, isCertificate, isGepir,
                                  onChangeField,onChangeCheckField,
                                  onClickSearch, onClickExcel, onClickReset
                              }) => {

    const districtOptions = () => {

        let items = [];
        if (aimak) {
            let ra = aimaks.filter(a=>a.id === aimak);
            if (ra.length>0) {
                ra[0].units.map(item=>{
                    items.push(<option key={item.id} value={item.id}>{item.name}</option>);
                })

            }
        }
        return items;
    };
    const aimaksOptions = () => {
        let items = [];
        aimaks.map(item=>{
            items.push(<option key={item.id} value={item.id}>{item.name}</option>);
        })

        return items;
    };
    const sectorsOptions = () => {
        let items = [];
        for (let key in sectors) {
            let s = sectors[key]
            items.push(<option key={s.id} value={s.id}>{s.name}</option>);
        }
        return items;
    };

    return (
            <div>
                <div className="field is-horizontal">
                    <div className="field-label is-normal">
                        <label className="label">Нэр</label>
                    </div>
                    <div className="field-body ">
                        <div className="control ">
                            <input className="input" name="name" type="text" value={name} style={{width: '400px'}}
                                   onChange={onChangeField} placeholder="Оруулах"/>
                        </div>
                    </div>
                </div>
                <div className="field is-horizontal">
                    <div className="field-label is-normal">
                        <label className="label">Регистр</label>
                    </div>
                    <div className="field-body">
                        <div className="control">
                            <input className="input"  name="regnumber" type="text" value={regnumber} style={{width: '400px'}}
                                   onChange={onChangeField} placeholder="Оруулах"/>
                        </div>
                    </div>
                </div>
                <div className="field is-horizontal">
                    <div className="field-label is-normal">
                        <label className="label">Утас</label>
                    </div>
                    <div className="field-body">
                        <div className="control">
                            <input className="input"  name="phone" type="text" value={phone} style={{width: '400px'}}
                                   onChange={onChangeField} placeholder="Оруулах"/>
                        </div>
                    </div>
                </div>
                <div className="field is-horizontal">
                    <div className="field-label is-normal">
                        <label className="label">Мэйл</label>
                    </div>
                    <div className="field-body">
                        <div className="control">
                            <input className="input" name="email" type="text" value={email} style={{width: '400px'}}
                                   onChange={onChangeField} placeholder="Оруулах"/>
                        </div>
                    </div>
                </div>
                <div className="field is-horizontal">
                    <div className="field-label is-normal">
                        <label className="label">Contact Мэйл</label>
                    </div>
                    <div className="field-body">
                        <div className="control">
                            <input className="input" name="contact_email" type="text" value={contact_email} style={{width: '400px'}}
                                   onChange={onChangeField} placeholder="Оруулах"/>
                        </div>
                    </div>
                </div>
                <div className="field is-horizontal">
                    <div className="field-label is-normal">
                        <label className="label">Аймаг/Хот</label>
                    </div>
                    <div className="field-body">

                        <div className="control">
                            <div className="select">
                                <select name="aimak" value={aimak} onChange={onChangeField}
                                        style={{width: '400px'}}>
                                    <option value=""></option>
                                    {aimaksOptions()}
                                </select>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="field is-horizontal">
                    <div className="field-label is-normal">
                        <label className="label">Дүүрэг</label>
                    </div>
                    <div className="field-body">
                        <div className="control">
                            <div className="select">
                                <select name="district" value={district} onChange={onChangeField}
                                        style={{width: '400px'}}>
                                    <option value=""></option>
                                    {districtOptions()}
                                </select>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="field is-horizontal">
                    <div className="field-label is-normal">
                        <label className="label">Үйл ажиллагааны чиглэл</label>
                    </div>
                    <div className="field-body">
                        <div className="control">
                            <div className="select">
                                <select name="sector" value={sector} onChange={onChangeField}
                                        style={{width: '400px'}}>
                                    <option value=""></option>
                                    {sectorsOptions()}
                                </select>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="field is-horizontal">
                    <div className="field-label is-normal">
                        <label className="label">Элссэн </label>
                    </div>
                    <div className="field-body">

                        <div className="control">
                            <input className="input" name="joinedYear" type="text" value={joinedYear}
                                   onChange={onChangeField} placeholder="Элссэн Он Оруулах"/>
                        </div>
                        <div className="control">
                            <input className="input" name="joinedMonth" type="text" value={joinedMonth}
                                   onChange={onChangeField} placeholder="Элссэн Сар Оруулах"/>
                        </div>

                    </div>
                </div>

                <div className="field is-horizontal">
                    <div className="field-label">
                        <label className="label">Экспорт хийдэг эсэх</label>
                    </div>
                    <div className="field-body">
                        <div className="field">
                            <div className="control">
                                <label className="checkbox">
                                    <input type="checkbox" name="isExported" checked={isExported}
                                           onChange={onChangeCheckField}/>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="field is-horizontal">
                    <div className="field-label">
                        <label className="label">Гэрчилгээ авсан эсэх</label>
                    </div>
                    <div className="field-body">
                        <div className="field">
                            <div className="control">
                                <label className="checkbox">
                                    <input type="checkbox" name="isCertificate" checked={isCertificate}
                                           onChange={onChangeCheckField}/>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="field is-horizontal">
                    <div className="field-label">
                        <label className="label">Gepir</label>
                    </div>
                    <div className="field-body">
                        <div className="field">
                            <div className="control">
                                <label className="checkbox">
                                <input type="checkbox" name="isGepir" checked={isGepir}
                                       onChange={onChangeCheckField}/>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="field is-horizontal">
                    <div className="field-label">

                    </div>
                    <div className="field-body">
                        <div className="field">
                            <div className="control">
                                <div className="buttons">
                                    <button onClick={onClickSearch} className="button bg-orange">
                                        <span style={{color: "white"}}>Хайх</span>
                                    </button>
                                    <button onClick={onClickExcel} className="button bg-blue">
                                        <span style={{color: "white"}}>Excel</span>
                                    </button>
                                    <button onClick={onClickReset} className="button bg-blue">
                                        <span style={{color: "white"}}>Reset</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    )
}

export default CompaniesPage

