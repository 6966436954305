/**
 * Created by gantushig on 11/6/15.
 */

import {Link, useParams, useRouteMatch} from "react-router-dom";
import React, {useEffect} from "react";
import {fetchProductNew, removeProduct} from "./actions/actions";
import {toast} from "react-toastify";
import history from "./history";
import {GLN, GTIN, SSCC, TOKEN} from "./Constants";
import ProductGTINView from "./ProductGTINView";
import ProductGLNView from "./ProductGLNView";
import ProductSSCCView from "./ProductSSCCView";
import {css} from "@emotion/react";
import {confirmAlert} from "react-confirm-alert";
import 'react-confirm-alert/src/react-confirm-alert.css';
import {getViewReducer} from "./reducers/productViewReducer"

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

const ProductView = ({onUpdate}) => {
    let {companyId, productId} = useParams()
    let {path, url} = useRouteMatch();
    const [state, dispatch] = getViewReducer(); //useReducer(reducer, initialState);
    let { product, image1, image2, image3 } = state;
    let token = localStorage.getItem(TOKEN) || null;

    useEffect(() => {
        fetchProductNew(dispatch, token, productId);
    }, [productId]);

    if (product === null) {
        return (
            <div>
                No Product
            </div>
        )
    }
    ;
    const handleDelete = () => {
        confirmAlert({
            title: 'Delete',
            message: 'Энэ бүтээгдэхүүнийг устгахдаа итгэлтэй байна уу?',
            buttons: [
                {
                    label: 'Тийм',
                    onClick: () => {
                        removeProduct(dispatch, token, productId).then(response => {
                               // setIsOpen(false)
                                toast.success("Амжилттай!");
                                history.push(`/company/${companyId}/products`)
                                //history.goBack();
                                onUpdate();

                            }
                        ).catch(error => {
                            toast.error(`Failed to delete org ${product.name}: ${error.message}`);
                        })
                        ;
                    }
                },
                {
                    label: 'Үгүй',
                },
            ]
        });
    }


    return (
        <>
            <div className="buttons has-addons is-right">
            <Link to={`${url}/edit`} className="button">
                Өөрчлөх
            </Link>
            <Link to={`${url}/copy`} className="button">
                Хуулах
            </Link>
            <a href="#" className="button" onClick={handleDelete}>Устгах</a>
            </div>

            {(product.barcode_type === null || product.barcode_type === GTIN) &&
                <ProductGTINView p={product}/>
            }
            {product.barcode_type === GLN &&
                <ProductGLNView p={product}/>
            }
            {product.barcode_type === SSCC &&
                <ProductSSCCView p={product}/>
            }
            <hr/>
            Device: {product.device}
            <hr/>
        </>
    )

}


export default ProductView;

