/**
 * Created by gantushig on 11/6/15.
 */

import {Link, Route, Switch, useLocation, useParams, useRouteMatch} from "react-router-dom";
import React, {useEffect, useReducer, useState} from "react";
import classnames from "classnames";
import ContactEditMenu from "./ContactEditMenu"
import {ActionTypes, TOKEN} from "./Constants";
import history from "./history";
import {css} from "@emotion/react";
import {toast} from "react-toastify";
import ContactEdit from "./ContactEdit";
import ContactCreate from "./ContactCreate";
import {fetchContacts, removeContact} from "./actions/actions";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

const initialState = {
    companyName:"",
    contacts: [],
    loading: false,
    refresh:false
};

const reducer = (state, action) => {
    switch (action.type) {
        case ActionTypes.FETCH_CONTACTS_REQUEST:
        case ActionTypes.DELETE_CONTACT_REQUEST:
            return {...state, loading: true            };
        case ActionTypes.FETCH_CONTACTS_SUCCESS:
            return {...state,                contacts: action.contacts,                loading: false            };
        case ActionTypes.DELETE_CONTACT_SUCCESS:
            return {...state,loading: false };
        case 'REFRESH':
            return {...state,refresh: !state.refresh };
        default:
            return state;
    }
};

const genderLabel = (gender) => {
    switch (gender) {
        case "MALE": return "Ноён";
        case "FEMALE": return "Хатагтай";
        default: return "";
    }
}
const positionTypeLabel = (position) => {
    let label = "";
    switch (position.position_type) {
        case "CEO": label = "Захирал"; break;
        case "GS1": label = "Код хариуцсан ажилтан"; break;
        case "ACC": label = "Нябо"; break;
        default: break;
    }
    return position.position ? `${label} / ${position.position}` : label;
};

const refreshPage = () => {
    return {
        type: "REFRESH",
    }
}

const CompanyContacts2 = () => {
    const {companyId} = useParams();
    let { path, url } = useRouteMatch();
    const [isOpen, setIsOpen] = useState(false);
    const [currContactId, setCurrContactId] = useState(0);
    const [state, dispatch] = useReducer(reducer, initialState);
    const {        contacts,        loading,        refresh,    } = state;
    let token = localStorage.getItem(TOKEN) || null;

    useEffect(() => {
        fetchContacts(dispatch, token, companyId);
    }, [companyId, refresh]);

    const handleEdit = (e, lid) => {
        e.preventDefault();
        history.push(`${url}/${lid}`)
    };
    const handleDelete = (e, lid) => {
        e.preventDefault();
        setIsOpen(true)
        setCurrContactId(lid)
    }

    const handleYes = () => {
        setIsOpen(false)
        removeContact(dispatch, token, currContactId).then(response => {
                toast.success("Амжилттай !");
                dispatch(refreshPage());
            }
        ).catch(error => {
            alert(error)
        });
    }
    const handleCancel = () => {
        setIsOpen(false)
    }

    const handleUpdate = () => {
        dispatch(refreshPage());
    }

    if (!contacts) {
        return <div>No Items</div>;
    }

    const modalClass = classnames({
        modal: true,
        'is-active': isOpen,
    });

    return (
        <>
            <Switch>
                <Route exact path={path}>
                    <div className="buttons is-right">
                        <Link to={`${url}/create`} className="button">
                            Үүсгэх
                        </Link>
                    </div>
                    <table className="table is-fullwidth">
                        <thead>
                        <tr>
                            <th>#</th>
                            <th>Нэр</th>
                            <th>Албан Тушаал</th>
                            <th>Утас</th>
                            <th>Утас2</th>
                            <th>Мэйл</th>
                            <th>Мэйл2</th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        {contacts.map((contact, index) => (
                            <tr key={contact.id} style={{ marginBottom: '10px' }}>
                                <td>{index + 1}</td>
                                <td>{genderLabel(contact.gender)} {contact.surname} {contact.name}</td>
                                <td>{positionTypeLabel(contact)}</td>
                                <td>{contact.phone}</td>
                                <td>{contact.phone2}</td>
                                <td>{contact.email}</td>
                                <td>{contact.email2}</td>
                                <td>
                                    <ContactEditMenu id={contact.id} onEdit={handleEdit} onDelete={handleDelete} />
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>

                    <div className={modalClass}>
                        <div className="modal-background"></div>
                        <div className="modal-card">
                            <header className="modal-card-head">
                                <p className="modal-card-title">Устгах?</p>
                                <button className="delete" aria-label="close"></button>
                            </header>
                            <section className="modal-card-body">
                                Устгахдаа итгэлтэй байна уу?
                            </section>
                            <footer className="modal-card-foot">
                                <button className="button is-danger" onClick={handleYes}>Тийм</button>
                                <button className="button" onClick={handleCancel}>Үгүй</button>
                            </footer>
                        </div>
                    </div>
                </Route>
                <Route  path={`${path}/create`}>
                    <ContactCreate onUpdate={handleUpdate}/>
                </Route>
                <Route  path={`${path}/:contactId`}>
                    <ContactEdit onUpdate={handleUpdate}/>
                </Route>
            </Switch>
        </>
    )

}


export default CompanyContacts2;


