import React, {useCallback, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import "./main.scss";
import {fetchDashboard, fetchInquiriesCount, logoutUser, menuClicked} from "./actions/actions";
import {Link} from "react-router-dom";
import ProgressBar from "./ProgressBar";
import {Helmet} from "react-helmet";
import classnames from "classnames";
import packageJson from '../package.json';
import {Menu, PageTitle} from "./Constants";


const MainWrapper = WrappedComponent => {
    const EnhancedComponent = (props) => {

        const dispatch = useDispatch();

        // Use useSelector to access the Redux state
        const token = useSelector(state => localStorage.getItem('id_token') || null);
        const active = useSelector(state => state.dashboard.companyActive);
        const tempClosed = useSelector(state => state.dashboard.companyTempClosed);
        const removed = useSelector(state => state.dashboard.companyRemoved);
        const invoice = useSelector(state => state.dashboard.invoice);
        const productAppl = useSelector(state => state.dashboard.productAppl);
        const companyAppl = useSelector(state => state.dashboard.companyAppl);
        const title = useSelector(state => state.ui.title);
        const sidemenu = useSelector(state => state.ui.sidemenu);
        const inquiriesCount = useSelector(state => state.dashboard.inquiries);

        // Fetch dashboard data when component mounts
        useEffect(() => {
            dispatch(fetchInquiriesCount);

            if (token) {
                dispatch(fetchDashboard(token));
            }
        }, [token, dispatch]);

        const handleLogout = useCallback(() => {
            dispatch(logoutUser());
        }, [logoutUser]);

        // const handleMenuClick = useCallback((menu, title) => {
        //     sideMenuClicked(menu, title);
        // }, [sideMenuClicked]);

        const getClassName = (sidemenu, menu) => {
            return classnames({
                'is-active': sidemenu === menu
            });
        };
        // const handleLogout = () => {
        //     logoutUser()
        // }
        //
        const handleMenuClick = (menu, title) => {
            dispatch(menuClicked(menu, title));
        };



        return (


            <div>
                <Helmet
                    bodyAttributes={{
                        class: "toolkit",
                    }}
                />


                <div style={{display: "flex", flexDirection: "row", height: "100vh", justifyContent: "flex-start"}}>
                    <div className="left-pane bg-blue">
                        <div className="sidebar-header" style={{padding: "0px", paddingLeft: "20px", paddingBottom: "0px"}}>
                            <a href="/">
                                <img alt="GS1 logo" style={{maxHeight: "80px", width: "auto"}}
                                     src="https://firebasestorage.googleapis.com/v0/b/gs1web.appspot.com/o/home%2F71346922_529046761240803_5443492588046778368_n.jpg?alt=media&token=ea01a5c0-935e-4aee-80b4-5c2106fc36db"/>
                            </a>
                        </div>

                        <ul className="left-menu">
                            <li className={getClassName(sidemenu, Menu.DASHBOARD)}><Link to="/" onClick={() => handleMenuClick(Menu.DASHBOARD, "Dashboard")}>Dashboard</Link></li>
                            <li className={getClassName(sidemenu, Menu.COMPANIES_ACTIVE)}>
                                <Link to="/companies/active" onClick={() => handleMenuClick(Menu.COMPANIES_ACTIVE, PageTitle.COMPANIES_ACTIVE)}>
                                    {PageTitle.COMPANIES_ACTIVE} {active > 0 && <span>({active})</span>}</Link></li>
                            <li className={getClassName(sidemenu, Menu.COMPANIES_REMOVED)}><Link to="/companies/removed" onClick={() => handleMenuClick(Menu.COMPANIES_REMOVED, PageTitle.COMPANIES_REMOVED)}>{PageTitle.COMPANIES_REMOVED} {removed > 0 && <span>({removed})</span>}</Link></li>
                            <li className={getClassName(sidemenu, Menu.COMPANIES_TEMP_CLOSED)}><Link to="/companies/temp_closed" onClick={() => handleMenuClick(Menu.COMPANIES_TEMP_CLOSED, PageTitle.COMPANIES_TEMP_CLOSED)}>{PageTitle.COMPANIES_TEMP_CLOSED} {tempClosed > 0 && <span>({tempClosed})</span>}</Link></li>
                            <li className={getClassName(sidemenu, Menu.GCPS)}><Link to="/gcps"
                                                                onClick={() => handleMenuClick(Menu.GCPS, PageTitle.GCPS)}>{PageTitle.GCPS}</Link>
                            </li>

                            <li className={getClassName(sidemenu, Menu.PRODUCTS)}><Link to="/products"
                                                                    onClick={() => handleMenuClick(Menu.PRODUCTS, PageTitle.PRODUCTS)}>{PageTitle.PRODUCTS}</Link>
                            </li>
                            <li className={getClassName(sidemenu, Menu.COMPANY_APPLS)}><Link to="/company-appls"
                                                                        onClick={() => handleMenuClick(Menu.COMPANY_APPLS, PageTitle.COMPANY_APPLS)}>{PageTitle.COMPANY_APPLS} {companyAppl > 0 &&
                                <span>({companyAppl})</span>}</Link></li>
                            <li className={getClassName(sidemenu, Menu.PROD_APPLS)}><Link to="/prod-appls"
                                                                     onClick={() => handleMenuClick(Menu.PROD_APPLS, PageTitle.PROD_APPLS)}>{PageTitle.PROD_APPLS} {productAppl > 0 &&
                                <span>({productAppl})</span>}</Link></li>
                            <li className={getClassName(sidemenu, Menu.INVOICES)}><Link to="/invoices"
                                                                    onClick={() => handleMenuClick(Menu.INVOICES, PageTitle.INVOICES)}>{PageTitle.INVOICES} {invoice > 0 &&
                                <span>({invoice})</span>}</Link></li>
                            <li className={getClassName(sidemenu, Menu.MAILS)}><Link to="/invoice-mails"
                                                                 onClick={() => handleMenuClick(Menu.MAILS, "Mails")}>Mails</Link>
                            </li>
                            <li className={getClassName(sidemenu, Menu.SMS)}><Link to="/sms-items"
                                                               onClick={() => handleMenuClick(Menu.SMS, "Sms")}>Sms</Link>
                            </li>

                            <li className={getClassName(sidemenu, "GS1-8")}><Link to="/product8s"
                                                                     onClick={() => handleMenuClick("GS1-8", "GS1-8")}>GS1-8</Link>
                            </li>
                            <li className={getClassName(sidemenu, "GS1-14")}><Link to="/products14"
                                                                      onClick={() => handleMenuClick("GS1-14", "GS1-14")}>GS1-14</Link>
                            </li>
                            <li className={getClassName(sidemenu, Menu.SETTINGS)}><Link to="/settings"
                                                                    onClick={() => handleMenuClick(Menu.SETTINGS, PageTitle.SETTINGS)}>{PageTitle.SETTINGS}</Link>
                            </li>
                            <li className={getClassName(sidemenu, Menu.PROFILE)}><Link to="/profile" onClick={() => handleMenuClick(Menu.PROFILE, PageTitle.PROFILE)}>{PageTitle.PROFILE}</Link></li>
                            <li className={getClassName(sidemenu, Menu.INQUIRY)}>
                                <Link to="/inquiries" onClick={() => handleMenuClick(Menu.INQUIRY, PageTitle.INQUIRY)}>
                                    {PageTitle.INQUIRY}{inquiriesCount > 0 && <span>({inquiriesCount})</span>}</Link></li>
                           <li className={getClassName(sidemenu, Menu.HQGCPS)}><Link to="/hq-gcps"
                                                                  onClick={() => handleMenuClick(Menu.HQGCPS, PageTitle.HQ_GCPS)}>{PageTitle.HQ_GCPS}</Link>
                            </li>
                            <li className={getClassName(sidemenu, Menu.HQBATCH)}><Link to="/hq-batches"
                                                                   onClick={() => handleMenuClick(Menu.HQBATCH, PageTitle.HQ_BATCH)}>{PageTitle.HQ_BATCH}</Link>
                            </li>
                            <li><a href="#" onClick={() => handleLogout()}>Logout</a></li>
                        </ul>
                    </div>

                    <div style={{flexGrow: "1", display: "flex", flexDirection: "column", overflowX: "scroll"}}>
                        <div style={{height: "80px", borderBottom: "1px solid #cdcdcd", backgroundColor: "white"}}>
                            <h1 style={{paddingLeft: "20px", fontSize: "28px", fontWeight: 600, color: "#002c6c"}}>
                                {title}</h1>
                        </div>
                        <div className="menu-pane">

                            <ul className="left-menu">
                                <li className={getClassName(sidemenu, Menu.DASHBOARD)}><Link to="/"
                                                                         onClick={() => handleMenuClick(Menu.DASHBOARD, "Dashboard")}>Dashboard</Link>
                                </li>
                                <li className={getClassName(sidemenu, Menu.COMPANIES_ACTIVE)}><Link to="/companies/active"
                                                                               onClick={() => handleMenuClick(Menu.COMPANIES_ACTIVE, "Байгууллагууд")}>
                                    Байгууллагууд {active > 0 &&  <span>({active})</span>}</Link></li>
                                <li className={getClassName(sidemenu, Menu.COMPANIES_REMOVED)}><Link to="/companies/removed"
                                                                                onClick={() => handleMenuClick(Menu.COMPANIES_REMOVED, "Байгууллагууд - Хасагдсан")}>Хасагдсан {removed > 0 &&
                                    <span>({removed})</span>}</Link></li>
                                <li className={getClassName(sidemenu, Menu.COMPANIES_TEMP_CLOSED)}><Link to="/companies/temp_closed"
                                                                               onClick={() => handleMenuClick(Menu.COMPANIES_TEMP_CLOSED, "Байгууллагууд - Түр Хаагдсан")}>Түр
                                    Хаагдсан {tempClosed > 0 &&
                                        <span>({tempClosed})</span>}</Link></li>
                                <li className={getClassName(sidemenu, Menu.GCPS)}><Link to="/gcps"                                                                    onClick={() => handleMenuClick(Menu.GCPS, "Gcps")}>Gcps</Link></li>
                                <li className={getClassName(sidemenu, Menu.PRODUCTS)}><Link to="/products" onClick={() => handleMenuClick(Menu.PRODUCTS, "Бүтээгдэхүүнүүд")}>Бүтээгдэхүүнүүд</Link></li>
                                <li className={getClassName(sidemenu, Menu.COMPANY_APPLS)}><Link to="/company-appls" onClick={() => handleMenuClick(Menu.COMPANY_APPLS, "Шинэ Байгууллага")}>Шинэ
                                    Байгууллага {companyAppl > 0 &&
                                        <span>({companyAppl})</span>}</Link></li>
                                <li className={getClassName(sidemenu, Menu.PROD_APPLS)}><Link to="/prod-appls"
                                                                         onClick={() => handleMenuClick(Menu.PROD_APPLS, "Шинэ Бүтээгдэхүүн")}>Шинэ
                                    Бүтээгдэхүүн {productAppl > 0 &&
                                        <span>({productAppl})</span>}</Link></li>
                                <li className={getClassName(sidemenu, Menu.INVOICES)}><Link to="/invoices" onClick={() => handleMenuClick(Menu.INVOICES, "Нэхэмжлэхүүд")}>Нэхэмжлэхүүд {invoice > 0 && <span>({invoice})</span>}</Link></li>
                                <li className={getClassName(sidemenu, Menu.MAILS)}><Link to="/invoice-mails" onClick={() => handleMenuClick(Menu.MAILS, "Mails")}>Mails</Link></li>
                                <li className={getClassName(sidemenu, Menu.SMS)}><Link to="/sms-items" onClick={() => handleMenuClick(Menu.SMS, "Sms")}>Sms</Link></li>
                                <li className={getClassName(sidemenu, "GS1-8")}><Link to="/product8s" onClick={() => handleMenuClick("GS1-8", "GS1-8")}>GS1-8</Link></li>
                                <li className={getClassName(sidemenu, Menu.SETTINGS)}><Link to="/settings" onClick={() => handleMenuClick(Menu.SETTINGS, "Тохиргоо")}>Тохиргоо</Link></li>
                                <li className={getClassName(sidemenu, Menu.PROFILE)}><Link to="/profile" onClick={() => handleMenuClick(Menu.PROFILE, "Профайл")}>Профайл</Link></li>
                                <li className={getClassName(sidemenu, Menu.INQUIRY)}>
                                    <Link to="/inquiries" onClick={() => handleMenuClick(Menu.INQUIRY, PageTitle.INQUIRY)}>
                                        {PageTitle.INQUIRY}{inquiriesCount > 0 && <span>({inquiriesCount})</span>}</Link></li>
                                <li className={getClassName(sidemenu, Menu.HQGCPS)}><Link to="/hq-gcps"
                                                                                          onClick={() => handleMenuClick(Menu.HQGCPS, PageTitle.HQ_GCPS)}>{PageTitle.HQ_GCPS}</Link>
                                </li>
                                <li className={getClassName(sidemenu, Menu.HQBATCH)}><Link to="/hq-batches"
                                                                                           onClick={() => handleMenuClick(Menu.HQBATCH, PageTitle.HQ_BATCH)}>{PageTitle.HQ_BATCH}</Link>
                                </li>
                                <li><a href="#" onClick={() => handleLogout()}>Logout</a></li>
                            </ul>
                        </div>
                        <div style={{flexGrow: "1"}}>
                            <div style={{backgroundColor: "#efefef", padding: "10px"}}>
                                <ProgressBar/>
                                <WrappedComponent {...props}/>
                            </div>
                            <footer>
                                <div style={{marginLeft: "10px"}}>
                                    <p>Copyright &copy;2019-{new Date().getFullYear()} GS1 Монгол Нийгэмлэг. The Global
                                        Language Of Business,
                                        Version {packageJson.version}</p>

                                </div>
                            </footer>
                        </div>
                    </div>

                </div>


            </div>

        );
    };
    return EnhancedComponent;
};


export default MainWrapper;
//
//
// const mapStateToProps = (state) => {
//     const token = localStorage.getItem('id_token') || null;
//
//     return {
//         token,
//         active: state.dashboard.companyActive,
//         tempClosed: state.dashboard.companyTempClosed,
//         removed: state.dashboard.companyRemoved,
//         gcp: state.dashboard.gcp,
//         gtin: state.dashboard.gtin,
//         invoice: state.dashboard.invoice,
//         productAppl: state.dashboard.productAppl,
//         companyAppl: state.dashboard.companyAppl,
//         title: state.ui.title,
//         sidemenu: state.ui.sidemenu
//     };
// }
// const mapDispatchToProps = (dispatch) => {
//     return {
//         logoutUser: () => dispatch(logoutUser())        ,
//         sideMenuClicked: (menu, title) => dispatch(menuClicked(menu, title)),
//         getDashboard: (token) => dispatch(fetchDashboard(token)),
//     }
// }
//
//
// const composedWrapApp = compose(connect(mapStateToProps, mapDispatchToProps), MainWrapper);
//
// export default composedWrapApp;
