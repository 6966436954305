/**
 * Created by gantushig on 11/6/15.
 */


import {Link} from "react-router-dom";
import {connect, useDispatch, useSelector} from "react-redux";
import React, {Component, useEffect, useState} from "react";
import {
    fetchAllProducts,
    fetchClassifs, menuClicked,
    printAllProducts,
    selectProduct,
    selectProductHeader
} from "./actions/actions";
import ReactPaginate from "react-paginate";
import qs from "query-string";
import dayjs from "dayjs";
import dayjsPluginUTC from "dayjs-plugin-utc";
import "react-datepicker/dist/react-datepicker.css";
import {Menu, Page, PageTitle, TOKEN} from "./Constants";
import history from "./history";

dayjs.extend(dayjsPluginUTC)

const ProductsPage = ({
                          location: {search}
                      }) => {
    const [companyName, setCompanyName] = useState(null);
    const [name, setName] = useState(null);
    const [barcodeType, setBarcodeType] = useState("GTIN");
    const [barcode, setBarcode] = useState(null);
    const [created, setCreated] = useState(null);
    const [from, setFrom] = useState(null);
    const [to, setTo] = useState(null);

    const [main, setMain] = useState("0");
    const [sub, setSub] = useState("0");
    const [unit, setUnit] = useState("0");
    const [isEmpty, setIsEmpty] = useState(false);
    const [isHq, setIsHq] = useState(false);

    const dispatch = useDispatch();
    let token = localStorage.getItem(TOKEN) || null;

    let parsed = qs.parse(search);
    let page = parsed[Page.PAGE];
    let order = parsed[Page.ORDER];
    let pagesize = parsed[Page.PAGESIZE];
    let asc = parsed[Page.ASC];
    let cname = parsed["co"];
    let qname = parsed["n"];
    let qbarcodetype = parsed["t"];
    let qbarcode = parsed["b"];
    let qmain = parsed["m"];
    let qsub = parsed["s"];
    let qunit = parsed["u"];
    let qempty = parsed["e"];
    let qhq = parsed["hq"];
    let qcreated = parsed["c"];
    let qfrom = parsed["fr"];
    let qto = parsed["to"];


    if (page === undefined) {
        page = "0";
    }
    if (order === undefined) {
        order = "barcode";
    }
    if (asc === undefined) {
        asc = "true";
    }
    if (pagesize === undefined) {
        pagesize = "25";
    }
    if (cname === undefined) {
        cname = "";
    }
    if (qname === undefined) {
        qname = "";
    }
    if (qbarcodetype === undefined) {
        qbarcodetype = "GTIN";
    }
    if (qbarcode === undefined) {
        qbarcode = "";
    }
    if (qmain === undefined) {
        qmain = "0";
    }
    if (qsub === undefined) {
        qsub = "0";
    }
    if (qunit === undefined) {
        qunit = "0";
    }
    if (qempty === undefined) {
        qempty = false;
    }
    if (qhq === undefined) {
        qhq = false;
    }
    if (qcreated === undefined) {
        qcreated = null;
    }
    if (qfrom === undefined) {
        qfrom = null;
    }
    if (qto === undefined) {
        qto = null;
    }

    const stateProducts = useSelector(state => state.products);
    const stateClassifs = useSelector(state => state.classifs);

    const mainItems = stateClassifs.main;
    const subItems = stateClassifs.subCats.get(main);
    const unitItems = stateClassifs.unitCats.get(sub);
    let selections = stateProducts.selections;

    useEffect(() => {
        dispatch(menuClicked(Menu.PRODUCTS, PageTitle.PRODUCTS));
    }, []);


    useEffect(() => {
        console.log('useEffect called');
        setMain(Number(qmain));
        setSub(Number(qsub));
        setUnit(Number(qunit));
        if (qempty === 'true') {
            setIsEmpty(true);
        } else {
            setIsEmpty(false);
        }
        if (qhq === 'true') {
            setIsHq(true);
        } else {
            setIsHq(false);
        }
        dispatch(fetchAllProducts(
            token,
            cname,
            qname,
            qbarcode, qbarcodetype, qmain, qsub, qunit, qempty, qhq, qcreated, qfrom, qto,
            page, pagesize, order, asc));
    }, [cname, qname, qbarcodetype, qbarcode, qmain, qsub, qunit, qempty, qhq, qcreated, qfrom, qto,  page, pagesize, order, asc]);

    const isItemSelected = id => selections.has(id);

    useEffect(() => {
        if (Object.keys(mainItems).length === 0 && mainItems.constructor === Object) {
            console.log('useEffect called');
            dispatch(fetchClassifs(token, "main", 0))
        }
    }, []);


    let main_ids_by_order = [];
    if (mainItems !== null && mainItems !== undefined) {
        main_ids_by_order = Object.values(mainItems)
            .reduce((ordered_ids, category) => {
                ordered_ids[category.order] = category.id
                return ordered_ids
            }, []);
    }
    let sub_ids_by_order = [];
    if (subItems !== null && subItems !== undefined) {
        sub_ids_by_order = Object.values(subItems)
            .reduce((ordered_ids, category) => {
                ordered_ids[category.order] = category.id
                return ordered_ids
            }, []);
    }
    let unit_ids_by_order = [];
    if (unitItems !== null && unitItems !== undefined) {
        unit_ids_by_order = Object.values(unitItems)
            .reduce((ordered_ids, category) => {
                ordered_ids[category.order] = category.id
                return ordered_ids
            }, []);
    }

    let meta = stateProducts.meta;
    if (meta === null) {
        meta = {};
        meta.totalPages = 0;
    }
    const products = stateProducts.products;
    let counter = 0;
    for (let key in products) {
        let item = products[key];
        counter++;
        item.counter = Number(page) * Number(pagesize) + counter;
    }

    const handleChangeName = (e) => {
        setName(e.target.value);
    };

    const handleChangeBarcode = (e) => {
        setBarcode(e.target.value);
    };

    const handleChangeCreated = (e) => {
        setCreated(e.target.value);
    };
    const handleChangeFrom = (e) => {
        setFrom(e.target.value);
    };
    const handleChangeTo = (e) => {
        setTo(e.target.value);
    };

    const handleChangeCompanyName = (e) => {
        setCompanyName(e.target.value);
    };

    const handlePageClick = (data) => {
        let parsed = qs.parse(search);
        parsed[Page.PAGE] = data.selected;
        history.push("?" + qs.stringify(parsed))
    };

    const handleSearch = (e) => {
        e.preventDefault();
        let parsed = qs.parse(search);
        parsed["co"] = companyName;
        parsed["n"] = name;
        parsed["t"] = barcodeType;
        parsed["b"] = barcode;
        parsed["m"] = main;
        parsed["s"] = sub;
        parsed["u"] = unit;
        parsed["e"] = isEmpty;
        parsed["hq"] = isHq;
        parsed["c"] = created;
        parsed["fr"] = from;
        parsed["to"] = to;
        parsed[Page.PAGE] = "0";
        history.push("?" + qs.stringify(parsed))
    };


    const handleExcel = (e) => {
        e.preventDefault();
        dispatch(printAllProducts(token, companyName, name, barcode, barcodeType, main, sub, unit, isEmpty, created, from, to));
    };

    const handleChangeMain = (e) => {
        setIsEmpty(false)
        setMain(e.target.value);
        setSub("0")
        setUnit("0")
    };
    const handleChangeSub = (e) => {
        setSub(e.target.value)
        setUnit("0")
    };
    const handleChangeUnit = (e) => {
        setUnit(e.target.value)
    };
    const handleChangeIsEmpty = (e) => {
        setIsEmpty(e.target.checked)
    };
    const handleChangeIsHq = (e) => {
        setIsHq(e.target.checked)
    };

    const handleChangeBarcodeType = (e) => {
        setBarcodeType(e.target.value)
    };

    const handleClickSelectSub = (e) => {
        let c = stateClassifs.subCats.get(main);
        if (c === null || c === undefined) {
            dispatch(fetchClassifs(token, "sub", main));
        }
    };
    const handleClickSelectUnit = (e) => {
        let c = stateClassifs.unitCats.get(sub);
        if (c === null || c === undefined) {
            dispatch(fetchClassifs(token, "unit", sub));
        }
    };

    const handleSelect = (id) => {
        dispatch(selectProduct(id))
    }

    const handleSelectHeader = () => {
        dispatch(selectProductHeader())
    }

    const handleUpdate = (id) => {
        history.push("products-update-cat");
    }
    const handleOrderClick = (f) => {
        let parsed = qs.parse(search);
        if (parsed[Page.ORDER] === f) {
            let o = parsed[Page.ASC];
            if (o === "true") {
                o = "false";
            } else {
                o = "true";
            }
            parsed[Page.ASC] = o;
        } else {
            parsed[Page.ORDER] = f;
        }


        history.push("?" + qs.stringify(parsed))

    }

    const appts2 = products.map(p => {
        return (
            <tr key={p.id}>
                <td>
                    <input className="checkbox" type="checkbox" checked={isItemSelected(p.id)}
                           onChange={() => handleSelect(p.id)}/>
                </td>
                <td>
                    <Link to={`/product/${p.id}`}>
                        {p.counter}
                    </Link>
                </td>
                <td>{p.company_name}</td>
                <td>
                    <Link to={`/product/${p.id}`}>
                        {p.name}
                    </Link>
                </td>
                <td>{p.brand_name}</td>
                <td>{p.barcode}</td>
                <td>{p.packaging} {p.packaging_unit}</td>
                <td>{p.created && dayjs.utc(p.created).format("MM/DD/YYYY")}</td>
                <td>
                    <Link to={`/hq-product-verify/${p.id}`}>
                        {p.modified && dayjs.utc(p.modified).format("MM/DD/YYYY")}
                    </Link>
                </td>
            </tr>
        )
    });

    return (

        <div className="inner-container">

            <ProductsSearchPanel
                mainItems={mainItems} subItems={subItems} unitItems={unitItems}
                main_ids_by_order={main_ids_by_order} sub_ids_by_order={sub_ids_by_order}
                unit_ids_by_order={unit_ids_by_order}
                companyName={companyName}
                name={name} barcodeType={barcodeType} barcode={barcode}
                main={main} sub={sub} unit={unit}
                isEmpty={isEmpty} isHq={isHq} created={created} from={from} to={to}
                onChangeCompanyName={handleChangeCompanyName}
                onChangeName={handleChangeName}
                onChangeBarcodeType={handleChangeBarcodeType}
                onChangeBarcode={handleChangeBarcode}
                onChangeMain={handleChangeMain}
                onChangeSub={handleChangeSub}
                onChangeUnit={handleChangeUnit}
                onClickSub={handleClickSelectSub}
                onClickUnit={handleClickSelectUnit}
                onChangeEmpty={handleChangeIsEmpty}
                onChangeHq={handleChangeIsHq}
                onChangeCreated={handleChangeCreated}
                onChangeFrom={handleChangeFrom}
                onChangeTo={handleChangeTo}
                onClickSearch={handleSearch} onClickExcel={handleExcel} onClickUpdate={handleUpdate}
            />

            <br/>
            <div>
                <h5 style={{marginBottom: "10px"}}><strong>Нийт: {meta.totalElements}</strong>
                </h5>

                <nav className="pagination">
                    <ReactPaginate previousLabel={"Өмнөх"}
                                   nextLabel={"Дараах"}
                                   initialPage={Number(page)}
                                   breakLabel={"..."}
                                   breakClassName={"pagination-ellipsis"}
                                   pageCount={meta.totalPages}
                                   marginPagesDisplayed={2}
                                   pageRangeDisplayed={5}
                                   activeLinkClassName={"is-current"}
                                   pageLinkClassName={"pagination-link"}
                                   onPageChange={(e) => handlePageClick(e)}
                                   disableInitialCallback={true}
                                   previousClassName={"pagination-previous"}
                                   nextClassName={"pagination-next"}
                                   containerClassName={"pagination-list"}
                                   forcePage={Number(page)}

                    />
                </nav>


            </div>
            <br/>
            <table className="table is-striped is-fullwidth">
                <thead>
                <tr>
                    <th><input className="checkbox" type="checkbox"
                               onChange={() => handleSelectHeader()}/></th>
                    <th><a onClick={() => handleOrderClick("id")}>#</a></th>
                    <th><a onClick={() => handleOrderClick("company")}>Компани</a></th>
                    <th><a onClick={() => handleOrderClick("name")}>Нэр</a></th>
                    <th>Брэнд Нэр</th>
                    <th>Зур.код</th>
                    <th>Савлалтын хэмжээ /нэгж, багц/</th>
                    <th><a onClick={() => handleOrderClick("created")}>Үүсгэсэн</a></th>
                    <th><a onClick={() => handleOrderClick("modified")}>Өөрчилсөн</a></th>
                </tr>
                </thead>
                <tbody>
                {appts2}
                </tbody>
            </table>

            <nav className="pagination">
                <ReactPaginate previousLabel={"Өмнөх"}
                               nextLabel={"Дараах"}
                               initialPage={Number(page)}
                               breakLabel={"..."}
                               breakClassName={"pagination-ellipsis"}
                               pageCount={meta.totalPages}
                               marginPagesDisplayed={2}
                               pageRangeDisplayed={5}
                               activeLinkClassName={"is-current"}
                               pageLinkClassName={"pagination-link"}
                               onPageChange={(e) => handlePageClick(e)}
                               previousClassName={"pagination-previous"}
                               nextClassName={"pagination-next"}
                               disableInitialCallback={true}
                               containerClassName={"pagination-list"}
                               forcePage={Number(page)}
                />
            </nav>
            <br/><br/>
        </div>
    )

}


const ProductsSearchPanel = ({
                                 mainItems, subItems, unitItems,
                                 main_ids_by_order, sub_ids_by_order, unit_ids_by_order,
                                 companyName, name, barcode, main, sub, unit, isEmpty, isHq, created, from, to, barcodeType,
                                 onChangeCompanyName,
                                 onChangeName,
                                 onChangeBarcode,
                                 onChangeBarcodeType,
                                 onChangeMain,
                                 onChangeSub,
                                 onChangeUnit,
                                 onChangeEmpty,
                                 onChangeHq,
                                 onChangeCreated,
                                 onChangeFrom,
                                 onChangeTo,
                                 onClickSub,
                                 onClickUnit,
                                 onClickSearch,
                                 onClickExcel,
                                 onClickUpdate
                             }) => {

    return (
        <div>

            <div className="field">
                <label className="label">Байгууллагын нэр</label>
                <div className="field-body ">
                    <div className="control ">
                        <input className="input" type="text" value={companyName} style={{width: '400px'}}
                               onChange={onChangeCompanyName} placeholder="Оруулах"/>
                    </div>
                </div>
            </div>

            <div className="field">
                <label className="label">Бүтээгдэхүүний нэр</label>
                <div className="field-body ">
                    <div className="control ">
                        <input className="input" type="text" value={name} style={{width: '400px'}}
                               onChange={onChangeName} placeholder="Оруулах"/>
                    </div>
                </div>
            </div>

            <div className="field">
                <label className="label">Баркод төрөл</label>
                <div className="field is-horizontal">
                    <div className="field-body">
                        <div className="control">
                            <label className="radio">
                                <input type="radio" value="GTIN" checked={barcodeType === "GTIN"}
                                       onChange={onChangeBarcodeType}/>
                                &nbsp;&nbsp;GTIN
                            </label>
                            <label className="radio">
                                <input type="radio" value="GLN" checked={barcodeType === "GLN"}
                                       onChange={onChangeBarcodeType}/>
                                &nbsp;&nbsp;GLN
                            </label>
                            <label className="radio">
                                <input type="radio" value="SSCC" checked={barcodeType === "SSCC"}
                                       onChange={onChangeBarcodeType}/>
                                &nbsp;&nbsp;SSCC
                            </label>
                        </div>
                    </div>
                </div>
            </div>

            <div className="field">
                <label className="label">Barcode</label>
                <div className="field-body ">
                    <div className="control ">
                        <input className="input" type="text" value={barcode} style={{width: '400px'}}
                               onChange={onChangeBarcode} placeholder="Оруулах"/>
                    </div>
                </div>
            </div>

            <div className="field">
                <label className="label">ҮНДСЭН АНГИЛАЛ</label>
                <div className="control has-icons-left">
                    <div className="select">
                        <select style={{width: "400px"}} value={main} onChange={onChangeMain}>
                            <option value="0"></option>
                            {main_ids_by_order.map(id => {
                                return (
                                    <option key={id} value={id}>{mainItems[id].name}-[{mainItems[id].code}]</option>
                                )
                            })
                            }
                        </select>
                    </div>
                </div>
            </div>

            <div className="field">
                <label className="label">ДЭД АНГИЛАЛ</label>
                <div className="control has-icons-left">
                    <div className="select">
                        <select style={{width: "400px"}} value={sub} onChange={onChangeSub} onClick={onClickSub}>
                            <option value="0"></option>
                            {main !== "0" && sub_ids_by_order.map(id => {
                                return (
                                    <option key={id} value={id}>{subItems[id].name} -[{subItems[id].code}]</option>
                                )
                            })
                            }
                        </select>
                    </div>
                </div>
            </div>


            <div className="field">
                <label className="label">НЭГЖ АНГИЛАЛ</label>
                <div className="control has-icons-left">
                    <div className="select">
                        <select style={{width: "400px"}} value={unit} onChange={onChangeUnit} onClick={onClickUnit}>
                            <option value="0"></option>
                            {sub !== "0" &&
                                unit_ids_by_order.map(id => {
                                    return (
                                        <option key={id} value={id}>{unitItems[id].name}-[{unitItems[id].code}]</option>
                                    )
                                })
                            }
                        </select>
                    </div>
                </div>
            </div>

            <div className="field">
                <label className="label">Хоосон</label>
                <div className="control has-icons-left">
                    <input className="checkbox" type="checkbox" checked={isEmpty}
                           onChange={onChangeEmpty}/>
                </div>
            </div>

            <div className="field">
                <label className="label">Эхлэх хугацаа YYYY/MM/DD</label>
                <div className="field-body ">
                    <div className="control ">
                        <input className="input" type="text" value={from} style={{width: '400px'}}
                               onChange={onChangeFrom} placeholder="Эхлэх хугацаа Оруулах"/>
                    </div>
                </div>
                <label className="label">Дуусах хугацаа YYYY/MM/DD</label>
                <div className="field-body ">
                    <div className="control ">
                        <input className="input" type="text" value={to} style={{width: '400px'}}
                               onChange={onChangeTo} placeholder="Дуусах хугацаа Оруулах"/>
                    </div>
                </div>
            </div>
            <div className="field">
                <label className="label">Hq</label>
                <div className="control has-icons-left">
                    <input className="checkbox" type="checkbox" checked={isHq}
                           onChange={onChangeHq}/>
                </div>
            </div>
            <div className="field is-horizontal">
                <div className="field-label">

                </div>
                <div className="field-body">
                    <div className="field">
                        <div className="control buttons">

                            <button onClick={onClickSearch} className="button bg-orange">
                                <span style={{color: "white"}}>Хайх</span>

                            </button>
                            <button onClick={onClickExcel} className="button bg-blue">
                                <span style={{color: "white"}}>Excel</span>
                            </button>
                            <button onClick={onClickUpdate} className="button bg-orange">
                                <span style={{color: "white"}}>Ангилал өөрчлөх</span>
                            </button>

                            <Link to={"products-edit"}>Edit</Link>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default ProductsPage


