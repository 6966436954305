/**
 * Created by gantushig on 4/16/17.
 */

import React, {useEffect, useReducer} from "react";
import ContactEditComponent from './ContactEditComponent';
import {useParams} from "react-router-dom";
import {ActionTypes, EndpointsExpress, TOKEN} from "./Constants";
import history from "./history";
import {css} from "@emotion/react";
import axios from "./axiosConfig";
import {toast} from "react-toastify";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

const initialState = {
    contact: {},
    loading: false
};

const reducer = (state, action) => {
    switch (action.type) {
        case ActionTypes.FETCH_CONTACT_REQUEST:
        case ActionTypes.UPDATE_CONTACT_REQUEST:
            return { ...state, loading: true };
        case ActionTypes.FETCH_CONTACT_SUCCESS:
            return { ...state, contact: action.contact, loading: false };
        case 'CHANGE_FIELD':
            return { ...state, contact: { ...state.contact, [action.name]: action.value }, loading: false };
        default:
            return state;
    }
};


const fetchContact = async (dispatch, token, contactId) => {
    dispatch({ type: ActionTypes.FETCH_CONTACT_REQUEST });
    try {
        const response = await axios.instance({
            headers: {
                "Authorization": token,
                "Accept": "application/json",
                "Content-Type": "application/json",
            },
            url: `${EndpointsExpress.CONTACT}/${contactId}`,
        });
        dispatch({ type: ActionTypes.FETCH_CONTACT_SUCCESS, contact: response.data.contact });
    } catch (error) {
        console.error(error);
    }
};

const updateContact = async (dispatch, token, contactId, info) => {
    dispatch({ type: ActionTypes.UPDATE_CONTACT_REQUEST });
    try {
        const response = await axios.instance({
            method: "post",
            headers: {
                "Authorization": token,
                "Accept": "application/json",
                "Content-Type": "application/json",
            },
            url: `${EndpointsExpress.CONTACT}/${contactId}`,
            data: JSON.stringify(info),
        });
        dispatch({ type: ActionTypes.UPDATE_CONTACT_SUCCESS, contact: response.data.contact });
        return response;
    } catch (error) {
        console.error(error);
        throw error;
    }
};

const ContactEdit = ({onUpdate}) => {
    const {companyId, contactId} = useParams();
    let token = localStorage.getItem(TOKEN) || null;
    const [state, dispatch] = useReducer(reducer, initialState);
    const {
        contact,
        loading
    } = state;

    useEffect(() => {
        fetchContact(dispatch, token, contactId);
    }, [contactId, token]);

    const handleSave = async () => {
        const dto = {
            name: contact.name,
            surname: contact.surname,
            gender: contact.gender,
            position_type: contact.position_type,
            position: contact.position,
            phone: contact.phone,
            phone2: contact.phone2,
            email: contact.email,
            email2: contact.email2,
        };
        try {
            await updateContact(dispatch, token, contactId, dto);
            toast.success("Амжилттай өөрчиллөө!");
            onUpdate();
            history.push(`/company/${contact.company_id}/contacts`);
        } catch (error) {
            alert(error);
        }
    };

    const handleCancel = () => {
        history.goBack();
    };

    const handleChangeField = (e) => {
        dispatch({ type: 'CHANGE_FIELD', name: e.target.name, value: e.target.value });
    };

    return (
        <>
            <div className="level">
                <div className="level-left" style={{paddingLeft:'10px', paddingTop:'10px'}}>
                    <h1 className="title is-size-4  has-text-info">Мэдээлэл оруулах</h1>
                </div>
            </div>
            <hr/>
            <ContactEditComponent
                contact={contact}
                onChangeField={handleChangeField}
                onSave={handleSave}
                onCancel={handleCancel}/>

        </>
    )
}


export default ContactEdit;