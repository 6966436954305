/**
 * Created by gantushig on 11/6/15.
 */

import {connect} from "react-redux";
import {Link} from "react-router-dom";
import { fetchDashboard} from "./actions/actions";
import React, {useEffect, useState} from "react";

const Dashboard = ({token, get,         active,
                       tempClosed,
                       removed,
                       gcp,
                       gtin,
                       invoice,
                       productAppl,
                       companyAppl
                   }) => {

    useEffect(() => {
        console.log('useEffect called');
        get(token)
    }, []);
    return (
        <div style={{background: 'white', paddingLeft: '20px', paddingRight: '20px', paddingTop: '20px'}}>
            <div className="columns">
                <div className="column">
                    <div className="card">
                        <div className="dashboard-header-container">
                            <div className="dashboard-header-left-item">
                                <Link to="/companies/active"><span>Нийт байгууллага</span></Link>
                            </div>
                            <div className="dashboard-header-right-item">
                                <span>{active}</span>
                            </div>
                        </div>
                        <div className="dashboard-main"></div>
                        <div className="dashboard-footer-container">
                            <div className="dashboard-footer-item">
                                  <span>{tempClosed}<br/>
                                      <Link to="/companies/temp_closed">Түр хаагдсан</Link>
                                  </span>
                            </div>
                            <div className="dashboard-footer-item">
                                  <span>{removed}<br/>
                                      <Link to="/companies/removed">Хасагдсан</Link>
                                  </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="column">
                    <div className="card">
                        <div className="dashboard-header-container">
                            <div className="dashboard-header-left-item">
                                <Link to="/company-appls"> <span>Шинэ байгууллага</span></Link>

                            </div>
                            <div className="dashboard-header-right-item">
                                <span>{companyAppl}</span>
                            </div>
                        </div>
                        <div className="dashboard-main"></div>
                        <div className="dashboard-footer-container">
                        </div>
                    </div>
                </div>
                <div className="column">
                    <div className="card">
                        <div className="card">
                            <div className="dashboard-header-container">
                                <div className="dashboard-header-left-item">
                                    <Link to="/prod-appls"> <span>Шинэ бүтээгдэхүүн</span></Link>
                                </div>
                                <div className="dashboard-header-right-item">
                                    <span>{productAppl}</span>
                                </div>
                            </div>
                            <div className="dashboard-main"></div>
                            <div className="dashboard-footer-container">
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="columns">
                <div className="column">
                    <div className="card">
                        <div className="dashboard-header-container">
                            <div className="dashboard-header-left-item">
                                <span>GCP</span>
                            </div>
                            <div className="dashboard-header-right-item">
                                <span>{gcp}</span>
                            </div>
                        </div>
                        <div className="dashboard-main"></div>
                        <div className="dashboard-footer-container">
                        </div>
                    </div>
                </div>
                <div className="column">
                    <div className="card">
                        <div className="dashboard-header-container">
                            <div className="dashboard-header-left-item">
                                <span>GTIN</span>
                            </div>
                            <div className="dashboard-header-right-item">
                                <span>{gtin}</span>
                            </div>
                        </div>
                        <div className="dashboard-main"></div>
                        <div className="dashboard-footer-container">
                        </div>
                    </div>
                </div>
                <div className="column">
                    <div className="card">
                        <div className="card">
                            <div className="dashboard-header-container">
                                <div className="dashboard-header-left-item">
                                    <Link to="/invoices"><span>Нэхэмжлэх</span></Link>
                                </div>
                                <div className="dashboard-header-right-item">
                                    <span>{invoice}</span>
                                </div>
                            </div>
                            <div className="dashboard-main"></div>
                            <div className="dashboard-footer-container">
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )

}


const mapStateToProps = (state, ownProps) => {

    let token = localStorage.getItem('id_token') || null
    const active = state.dashboard.companyActive
    const tempClosed = state.dashboard.companyTempClosed
    const removed = state.dashboard.companyRemoved
    const gcp = state.dashboard.gcp
    const gtin = state.dashboard.gtin
    const invoice = state.dashboard.invoice
    const productAppl = state.dashboard.productAppl
    const companyAppl = state.dashboard.companyAppl
    return {
        token,
        active,
        tempClosed,
        removed,
        gcp,
        gtin,
        invoice,
        productAppl,
        companyAppl
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        get: (token) => {
            dispatch(fetchDashboard(token))
        },
    }

};


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Dashboard)

