/**
 * Created by gantushig on 11/6/15.
 */


import React, {useEffect, useMemo, useState} from "react";
import ReactPaginate from "react-paginate";
import qs from "query-string";
import dayjs from "dayjs";
import dayjsPluginUTC from "dayjs-plugin-utc";
import "react-datepicker/dist/react-datepicker.css";
import {EndpointsExpress, Page} from "./Constants";
import history from "./history";
import axios from './axiosConfig'


dayjs.extend(dayjsPluginUTC)


const InquiriesPage = ({
                           location: {search}
                       }) => {

    const [inquiries, setInquiries] = useState([]);
    const [meta, setMeta] = useState({});

    const parsed = useMemo(() => qs.parse(search), [search]);
    const page = parsed[Page.PAGE] || "0";
    const pagesize = parsed[Page.PAGESIZE] || "15";

    useEffect(() => {

        const fetchInquiries = async () => {
            const response = await axios.instance({
                method: 'GET',
                url: `${EndpointsExpress.INQUIRIES}`,
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json"
                },
                params: {
                    page,
                    pagesize
                }
            });
            const {inquiries} = response.data;
            const updatedInquiries = inquiries.map((item, index) => ({
                ...item,
                counter: Number(page) * Number(pagesize) + index + 1
            }));
            setInquiries(updatedInquiries);
            setMeta(response.data.meta);
        }
        fetchInquiries();
    }, [page, pagesize]);


    const handlePageClick = (data) => {
        parsed[Page.PAGE] = data.selected;
        history.push("?" + qs.stringify(parsed));
    };

    const handleClick = (p) => {
        if (p.processed) {
            return;
        }
        console.log("clicked", p.id);
        const updateInquiry = async (id) => {
            const response = await axios.instance({
                method: 'POST',
                url: `${EndpointsExpress.INQUIRY}/${id}`,
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json"
                },
            });
        }
        updateInquiry(p.id);
        setInquiries(prevInquiries =>
            prevInquiries.map(inquiry =>
                inquiry.id === p.id ? { ...inquiry, processed: new Date() } : inquiry
            )
        );
    };

    const getRowClassName = (processed) => {
        let rowClass = "bg-gray-200";
        if (processed) {
            rowClass = "bg-white";
        }
        return rowClass;
    }

    const items = inquiries.map(p =>
        (
            <tr className={getRowClassName(p.processed)} onClick={() => handleClick(p)} key={p.id}>
                <td>{p.counter}</td>
                <td>
                    <div >
                        <span className="text-gray-600 font-bold">Нэр:&nbsp;</span> <span> &nbsp;{p.name}&nbsp;</span>
                        <span className="text-gray-600 font-bold">Е-Мэйл:&nbsp;</span>
                        <span> &nbsp;{p.email}&nbsp;</span>
                        <span
                            className="text-gray-600 font-bold">Компани:&nbsp;</span><span>&nbsp;{p.company}&nbsp;</span>
                        <span className="text-gray-600 font-bold">Утас:&nbsp;</span><span>&nbsp;{p.phone}&nbsp;</span>
                        <div><span className="text-gray-600 font-bold">Зурвас:&nbsp;</span>{p.note}</div>
                    </div>
                </td>
            </tr>
        )
    );

    return (

        <div className="inner-container">
            <br/>
            <div>
                <h5 style={{marginBottom: "10px"}}><strong>Нийт {meta.totalElements}</strong>
                </h5>
            </div>
            <br/>
            <table className="table is-fullwidth">
                <thead>
                <tr>
                    <th>#</th>
                    <th>Message</th>
                </tr>
                </thead>
                <tbody>
                {items}
                </tbody>
            </table>

            <nav className="pagination">
                <ReactPaginate previousLabel={"Өмнөх"}
                               nextLabel={"Дараах"}
                               initialPage={Number(page)}
                               breakLabel={"..."}
                               breakClassName={"pagination-ellipsis"}
                               pageCount={meta.totalPages}
                               marginPagesDisplayed={2}
                               pageRangeDisplayed={5}
                               activeLinkClassName={"is-current"}
                               pageLinkClassName={"pagination-link"}
                               onPageChange={(e) => handlePageClick(e)}
                               previousClassName={"pagination-previous"}
                               nextClassName={"pagination-next"}
                               containerClassName={"pagination-list"}
                               forcePage={Number(page)}
                />
            </nav>

        </div>
    )

}

export default InquiriesPage

