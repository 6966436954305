/**
 * Created by gantushig on 4/16/17.
 */

import React, {useReducer} from "react";

import CompanyEditComponent from './CompanyEditComponent';
import dayjs from "dayjs"
import {ActionTypes, EndpointsExpress, TOKEN} from "./Constants";
import {css} from "@emotion/react";
import axios from './axiosConfig'
import history from "./history"
import ClipLoader from "react-spinners/ClipLoader";


const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

const initialState = {
    company: {},
    loading: false
};

const reducer = (state, action) => {
    switch (action.type) {
        case ActionTypes.CREATE_COMPANY_REQUEST: {
            return {
                ...state,
                loading: true
            }
        }
        case ActionTypes.CREATE_COMPANY_SUCCESS: {
            let c = {...action.company}
            if ('null' != c.joined) {
                c.joined = Date.parse(c.joined); //"2019-06-12T10:20:30Z"); //// dayjs("2019-06-12 00:00:00"); c.issued);
            }
            if ('null' != c.established) {
                c.established = Date.parse(c.established); //"2019-06-29T01:00:00Z");//c.established);
            }
            return {
                ...state,
                company: c,
                loading: false
            }
        }
        case 'CHANGE_FIELD':
            let c = {...state.company};
            c[action.name] = action.value
            if (action.name === "sector") {
                c[action.name] = Number(action.value);
            }
            return {
                ...state,
                company: c
            }
        default:
            return state;
    }
};


const createCompanyRequest = () => {
    return {
        type: ActionTypes.CREATE_COMPANY_REQUEST
    }
};

const createCompanySuccess = (company) => {
    return {
        type: ActionTypes.CREATE_COMPANY_SUCCESS,
        company
    }
};

const createCompany = (dispatch, token, info) => {

    return new Promise(function (resolve, reject) {
        dispatch(createCompanyRequest())
        let header = {}
        header["Authorization"] = token
        header["Accept"] = "application/json"
        header["Content-Type"] = "application/json"

        let config = {}
        config["headers"] = header;
        config["url"] = `${EndpointsExpress.COMPANIES}/create`;
        config["method"] = "post";
        config["data"] = JSON.stringify(info);

        axios.instance(config)
            .then(response => {

                dispatch(createCompanySuccess(response.data.company))
                resolve(response)
            })
            .catch(error => {
                reject(error);

            });
    });
};

const changeField = (name, value) => {
    return {
        type: 'CHANGE_FIELD',
        name,
        value
    }
}

const CompanyCreate = () => {
    let token = localStorage.getItem(TOKEN) || null;
    const [state, dispatch] = useReducer(reducer, initialState);
    const {company, loading} = state;

    const handleSave = ( ) => {

        let established_str = null;
        if (company.established !== null ) {
            company.established = company.established.getTime()
            established_str = dayjs(company.established).format('YYYY-MM-DD');
        }

        let joined_str = null;
        if (company.joined !== null ) {
            company.joined = company.joined.getTime()
            joined_str = dayjs(company.joined).format('YYYY-MM-DD');
        }

        console.log('send');
        let dto = {
            name:company.name,
            name_english:company.name_english,
            state_reg_number:company.state_reg_number,
            registration_number:company.registration_number,
            established:company.established,
            soum:company.soum,
            joined:company.joined,
            district:company.district,
            aimak:company.aimak,
            address:company.address,
            phone:company.phone,
            postal_address:company.postal_address,
            email:company.email,
            social:company.social,
            web:company.web,
            is_gepir:company.is_gepir,
            is_certificate:company.is_certificate,
            sector:company.sector,
            zipcode:company.zipcode,
            postal_code:company.postal_code,
            is_exported:company.is_exported,
            is_exported_countries:company.is_exported_countries,
            established_str,
            joined_str
        };

        createCompany( dispatch, token, dto).then(response => {
                alert("Амжилттай үүсгэлээ!")
                const cId = response.company.id;
                history.push(`/company/${cId}`);
            }
        ).catch(error => {
            alert(error)
        })
    };


    const handleChangeJoined = (date) => {
        dispatch(changeField("joined", date))

    };
    const handleChangeEstablished = (date) => {
        dispatch(changeField("established", date))
    };

    const handleChangeCheckField = (e) => {
        dispatch(changeField(e.target.name, e.target.checked))
    }
    const handleChangeField = (e) => {
        dispatch(changeField(e.target.name, e.target.value))
    }
    const handleCancel = () => {
        console.log('cancel');
        history.goBack();
    };

    return (
        <div className="inner-container">
            <div className="level">
                <div className="level-left" style={{paddingLeft:'10px', paddingTop:'10px'}}>
                    <h1 className="title is-size-4  has-text-info">Мэдээлэл оруулах</h1>
                </div>
            </div>
            <hr/>
            <ClipLoader color="#ffffff" loading={loading} css={override} size={150}/>
            <CompanyEditComponent company={company} isCreate={true}
                                  onChangeEstablished={handleChangeEstablished}
                                  onChangeJoined={handleChangeJoined}
                                  onChangeField={handleChangeField}
                                  onChangeCheckField={handleChangeCheckField}
                onSave={handleSave} onCancel={handleCancel}/>
        </div>
    )
}

export default CompanyCreate;