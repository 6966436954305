import "./scss/mystyles.scss";
import {Route, Router, Switch} from "react-router-dom";
import { createRoot } from 'react-dom/client';
import {applyMiddleware, createStore} from "redux";
import thunkMiddleware from "redux-thunk";
import {Provider} from "react-redux";
import rootReducer from "./reducers/reducers";

import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import composedWrapApp from "./App";
import composedCompanySelfWrap from "./CompanySelfWrapper";
import CompaniesPage from "./CompaniesPage";
import CompanyCreate from "./CompanyCreate";
import Signup from "./Signup";
import CompanySelfCreateCompleted from "./CompanySelfCreateCompleted";
import HqGcpsPage from "./HqGcpsPage";
import GcpsPage from "./GcpsPage";
import ProductCreate from "./ProductCreate";
import ProductsUpdateCat from "./ProductsUpdateCat";
import ProductImagesView from "./ProductImagesView";
import ProductOneView from "./ProductOneView";
import ProductOneEdit from "./ProductOneEdit";
import Dashboard from "./Dashboard";
import LoginEmail from "./LoginEmail";
import LoginEmailOld from "./LoginEmailOld";
import Profile from "./Profile";
import ProductsPage from "./ProductsPage";
import AdminUserCreate from "./AdminUserCreate";
import AdminUserPasswordChange from "./AdminUserPasswordChange";

import CompanyPayments from "./CompanyPayments";
import CompanyInvoices from "./CompanyInvoices";
import SectorsPage from "./SectorsPage";
import SectorCreate from "./SectorCreate";
import SectorEdit from "./SectorEdit";
import FeeConfig from "./FeeConfig";
import BarcodeCreate from "./BarcodeCreate";
import BarcodeEdit from "./BarcodeEdit";
import BarcodesPage from "./BarcodesPage";
import CompanyApplications from "./CompanyApplications";
import ProductApplications from "./ProductApplications";
import ProductApplicationEdit from "./ProductApplicationEdit";
import CompanyApplicationEdit from "./CompanyApplicationEdit";
import InvoiceView from "./InvoiceView";
import InvoicesPage from "./InvoicesPage";
import ProductClassificationConfig from "./ProductClassificationConfig";
import SettingsPage from "./SettingsPage";

import * as serviceWorker from "./serviceWorker";
import requireAuth from "./AccountManager";
import ReactGA from "react-ga";
import {ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import ProductRequestNoteCreate from "./ProductRequestNoteCreate";
import Product8sPage from "./Product8sPage";
import Product8Create from "./Product8Create";
import Product8View from "./Product8View";
import Product8Edit from "./Product8Edit";
import Product8FileCreate from "./Product8FileCreate";
import Product8FileEdit from "./Product8FileEdit";
import CompanyProduct8s from "./CompanyProduct8s";
import CompanyProduct8Files from "./CompanyProduct8Files";
import CompanyViewContainer from "./CompanyViewContainer";
import CompanySelfCreate from "./CompanySelfCreate";
import InvoicesMailPage from "./InvoicesMailPage";
import InvoicesMailItemsPage from "./InvoicesMailItemsPage";
import InvoiceMailTemplateCreate from "./InvoiceMailTemplateCreate";
import InvoiceMailTemplateEdit from "./InvoiceMailTemplateEdit";
import InvoiceMailTemplateView from "./InvoiceMailTemplateView";
import LoginExpress from "./LoginExpress";
import axios from "./axiosConfig"
import history from "./history"
import Waiting from "./Waiting";
import SmsTemplateEdit from "./SmsTemplateEdit";
import SmsItemsPage from "./SmsItemsPage";
import ProductsEditAllPage from "./ProductsEditAllPage";
import Products14Page from "./Products14Page";
import HqProductsPage from "./HqProductsPage";
import HqProductView from "./HqProductView";
import HqBatchPage from "./HqBatchPage";
import HqGtinDeletePage from "./HqGtinDeletePage";
import HqGcpDeletePage from "./HqGcpDeletePage";
import HqProductVerify from "./HqProductVerify";
import HqBatchesPage from "./HqBatchesPage";
import HqGtinSend from "./HqGtinSend";
import GcpVerify from "./GcpVerify";
import GcpEditOne from "./GcpEditOne";
import HqGcpSend from "./HqGcpSend";
import SentEmailsPage from "./SentEmailsPage";
import ProductImageApplications from "./ProductImageApplications";
import ProductImageRequestNoteCreate from "./ProductImageRequestNoteCreate";
import ProductImageApplicationEdit from "./ProductImageApplicationEdit";

import SmsMassBodiesPage from "./SmsMassBodiesPage";
import SmsMassCreate from "./SmsMassCreate";
import SmsMassEdit from "./SmsMassEdit";
import SmsMassItemsPage from "./SmsMassItemsPage";
import ProductsEditVerifyAllPage from "./ProductsEditVerifyAllPage";
import SignupIndividual from "./SignupIndividual";
import SignupCompany from "./SignupCompany";
import CompanySelfCreateTest from "./CompanySelfCreateTest";
import ProductBarcodeImageView from "./ProductBarcodeImageView";
import SignupCompleted from "./SignupCompleted";
import InquiriesPage from "./InquiriesPage";

const initializeReactGA = () => {
    ReactGA.initialize('UA-138588125-1');
    ReactGA.pageview('/homepage');
}

initializeReactGA();

const store = createStore(
    rootReducer,
    applyMiddleware(thunkMiddleware)
);
axios.setupInterceptors(store, history);

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
    <Provider store={store}>
        <div>
            <ToastContainer/>
            <Router history={history}>


                <Switch>
                    <Route path="/" exact={true} component={requireAuth(composedWrapApp(Dashboard))}/>
                    <Route path="/profile" component={requireAuth(composedWrapApp(Profile))}/>
                    <Route path="/companies/:status" component={requireAuth(composedWrapApp(CompaniesPage))}/>
                    <Route path="/gcps" component={requireAuth(composedWrapApp(GcpsPage))}/>
                    <Route path="/hq-gcps" component={requireAuth(composedWrapApp(HqGcpsPage))}/>
                    <Route path="/hq-products" component={requireAuth(composedWrapApp(HqProductsPage))}/>
                    <Route path="/hq-product/:barcode" component={requireAuth(composedWrapApp(HqProductView))}/>
                    <Route path="/hq-batch" component={requireAuth(composedWrapApp(HqBatchPage))}/>
                    <Route path="/hq-batches" component={requireAuth(composedWrapApp(HqBatchesPage))}/>
                    <Route path="/hq-product-verify/:productId"
                           component={requireAuth(composedWrapApp(HqProductVerify))}/>
                    <Route path="/hq-gtin-delete" component={requireAuth(composedWrapApp(HqGtinDeletePage))}/>
                    <Route path="/hq-gcp-delete" component={requireAuth(composedWrapApp(HqGcpDeletePage))}/>
                    <Route path="/hq-gtin-send" component={requireAuth(composedWrapApp(HqGtinSend))}/>
                    <Route path="/hq-gcp-send" component={requireAuth(composedWrapApp(HqGcpSend))}/>
                    <Route path="/gcp-edit/:gcpId" component={requireAuth(composedWrapApp(GcpEditOne))}/>
                    <Route path="/gcp-verify/:gcpId" component={requireAuth(composedWrapApp(GcpVerify))}/>

                    <Route path="/products" component={requireAuth(composedWrapApp(ProductsPage))}/>
                    <Route path="/products14" component={requireAuth(composedWrapApp(Products14Page))}/>
                    <Route path="/products-update-cat" component={requireAuth(composedWrapApp(ProductsUpdateCat))}/>
                    <Route path="/product/:productId" exact={true}
                           component={requireAuth(composedWrapApp(ProductOneView))}/>
                    <Route path="/product/:productId/edit" component={requireAuth(composedWrapApp(ProductOneEdit))}/>
                    <Route path="/company-create" component={requireAuth(composedWrapApp(CompanyCreate))}/>
                    <Route path="/company-self-create" component={composedCompanySelfWrap(CompanySelfCreate)}/>
                    <Route path="/company-self-create-test" component={composedCompanySelfWrap(CompanySelfCreateTest)}/>
                    <Route path="/company-test" component={composedCompanySelfWrap(Signup)}/>

                    <Route path="/product8s" exact={true} component={requireAuth(composedWrapApp(Product8sPage))}/>
                    <Route path="/product8s/create" component={requireAuth(composedWrapApp(Product8Create))}/>
                    <Route path="/product8/:productId" exact={true}
                           component={requireAuth(composedWrapApp(Product8View))}/>
                    <Route path="/company/:companyId/product8-file-create" exact={true}
                           component={requireAuth(composedWrapApp(Product8FileCreate))}/>
                    <Route path="/product8/:productId/edit" component={requireAuth(composedWrapApp(Product8Edit))}/>

                    <Route path="/product8-file/:fileId" exact={true}
                           component={requireAuth(composedWrapApp(Product8FileEdit))}/>

                    <Route path="/signup/completed" component={composedCompanySelfWrap(SignupCompleted)}/>
                    <Route path="/signup" exact={true} component={composedCompanySelfWrap(CompanySelfCreate)}/>
                    <Route path="/company/register/completed"
                           component={composedCompanySelfWrap(CompanySelfCreateCompleted)}/>


                    <Route path="/company/:id/gcp/:gcpId/barcodes"
                           component={requireAuth(composedWrapApp(BarcodesPage))}/>
                    <Route path="/company/:id/gcp/:gcpId/barcode-create"
                           component={requireAuth(composedWrapApp(BarcodeCreate))}/>
                    <Route path="/company/:id/gcp/:gcpId/barcode/:barcodeId"
                           component={requireAuth(composedWrapApp(BarcodeEdit))}/>
                    <Route path="/company/:id/gcp/:gcpId/payments"
                           component={requireAuth(composedWrapApp(CompanyPayments))}/>
                    <Route path="/company/:id/gcp/:gcpId/invoices"
                           component={requireAuth(composedWrapApp(CompanyInvoices))}/>
                    <Route path="/company/:id/gcp/:gcpId/invoice/:invoiceId"
                           component={requireAuth(composedWrapApp(InvoiceView))}/>

                    <Route path="/company/:id/gcp/:gcpId/:prefix/:size/product-create"
                           component={requireAuth(composedWrapApp(ProductCreate))}/>

                    <Route path="/company/:companyId/product8s"
                           component={requireAuth(composedWrapApp(CompanyProduct8s))}/>
                    <Route path="/company/:companyId/product8-files"
                           component={requireAuth(composedWrapApp(CompanyProduct8Files))}/>

                    <Route path="/company/:companyId"
                           component={requireAuth(composedWrapApp(CompanyViewContainer))}/>
                    <Route path="/products-edit" component={requireAuth(composedWrapApp(ProductsEditAllPage))}/>
                    <Route path="/products-verify" component={requireAuth(composedWrapApp(ProductsEditVerifyAllPage))}/>

                    <Route path="/signup-individual" component={composedCompanySelfWrap(SignupIndividual)}/>
                    <Route path="/signup-company" component={composedCompanySelfWrap(SignupCompany)}/>


                    <Route path="/product/:productId/images" exact={true}
                           component={requireAuth(composedWrapApp(ProductImagesView))}/>

                    <Route path="/settings" component={requireAuth(composedWrapApp(SettingsPage))}/>
                    <Route path="/prod-appls" component={requireAuth(composedWrapApp(ProductApplications))}/>
                    <Route path="/prod-image-appls" component={requireAuth(composedWrapApp(ProductImageApplications))}/>
                    <Route path="/prod-appl/:applId" exact={true}
                           component={requireAuth(composedWrapApp(ProductApplicationEdit))}/>
                    <Route path="/prod-appl/deny/:applId" exact={true}
                           component={requireAuth(composedWrapApp(ProductRequestNoteCreate))}/>

                    <Route path="/prod-image-appl/deny/:applId" exact={true}
                           component={requireAuth(composedWrapApp(ProductImageRequestNoteCreate))}/>

                    <Route path="/prod-image-appl/:productId/:applId" exact={true}
                           component={requireAuth(composedWrapApp(ProductImageApplicationEdit))}/>
                    <Route path="/barcode-image-edit/:barcode" exact={true}
                           component={requireAuth(composedWrapApp(ProductBarcodeImageView))}/>


                    <Route path="/company-appls" component={requireAuth(composedWrapApp(CompanyApplications))}/>
                    <Route path="/company-appl/:applId" exact={true}
                           component={requireAuth(composedWrapApp(CompanyApplicationEdit))}/>
                    <Route path="/sectors" component={requireAuth(composedWrapApp(SectorsPage))}/>
                    <Route path="/sector-create" component={requireAuth(composedWrapApp(SectorCreate))}/>
                    <Route path="/sector/:id/edit" exact={true} component={requireAuth(composedWrapApp(SectorEdit))}/>

                    <Route path="/classifs" component={requireAuth(composedWrapApp(ProductClassificationConfig))}/>

                    <Route path="/invoices" component={requireAuth(composedWrapApp(InvoicesPage))}/>
                    <Route path="/fees" component={requireAuth(composedWrapApp(FeeConfig))}/>

                    <Route path="/invoice-mails" component={requireAuth(composedWrapApp(InvoicesMailPage))}/>
                    <Route path="/invoice-mail-items/:id"
                           component={requireAuth(composedWrapApp(InvoicesMailItemsPage))}/>
                    <Route path="/invoice-mail-template/create"
                           component={requireAuth(composedWrapApp(InvoiceMailTemplateCreate))}/>
                    <Route path="/invoice-mail-template/:id/edit"
                           component={requireAuth(composedWrapApp(InvoiceMailTemplateEdit))}/>
                    <Route path="/invoice-mail-template"
                           component={requireAuth(composedWrapApp(InvoiceMailTemplateView))}/>
                    <Route path="/login" component={LoginEmail}/>
                    <Route path="/login-express" component={LoginExpress}/>
                    <Route path="/waiting" component={Waiting}/>
                    <Route path="/logintest" component={LoginEmailOld}/>
                    <Route path="/admin-create" component={requireAuth(composedWrapApp(AdminUserCreate))}/>
                    <Route path="/admin-pwd-change/:id"
                           component={requireAuth(composedWrapApp(AdminUserPasswordChange))}/>

                    <Route path="/sms-template-edit" component={requireAuth(composedWrapApp(SmsTemplateEdit))}/>
                    <Route path="/sms-items" component={requireAuth(composedWrapApp(SmsItemsPage))}/>
                    <Route path="/sent-emails" component={requireAuth(composedWrapApp(SentEmailsPage))}/>

                    <Route path="/sms-mass-bodies" component={requireAuth(composedWrapApp(SmsMassBodiesPage))}/>
                    <Route path="/sms-mass-body-create" component={requireAuth(composedWrapApp(SmsMassCreate))}/>
                    <Route path="/sms-mass-body-edit/:id" component={requireAuth(composedWrapApp(SmsMassEdit))}/>
                    <Route path="/sms-mass-body/:id" component={requireAuth(composedWrapApp(SmsMassItemsPage))}/>
                    <Route path="/inquiries" component={requireAuth(composedWrapApp(InquiriesPage))}/>
                </Switch>
            </Router>
        </div>
    </Provider>
);

// ReactDOM.render(
//     <Provider store={store}>
//         <div>
//             <ToastContainer/>
//             <Router history={history}>
//
//
//                 <Switch>
//                     <Route path="/" exact={true} component={requireAuth(composedWrapApp(Dashboard))}/>
//                     <Route path="/profile" component={requireAuth(composedWrapApp(Profile))}/>
//                     <Route path="/companies/:status" component={requireAuth(composedWrapApp(CompaniesPage))}/>
//                     <Route path="/gcps" component={requireAuth(composedWrapApp(GcpsPage))}/>
//                     <Route path="/hq-gcps" component={requireAuth(composedWrapApp(HqGcpsPage))}/>
//                     <Route path="/hq-products" component={requireAuth(composedWrapApp(HqProductsPage))}/>
//                     <Route path="/hq-product/:barcode" component={requireAuth(composedWrapApp(HqProductView))}/>
//                     <Route path="/hq-batch" component={requireAuth(composedWrapApp(HqBatchPage))}/>
//                     <Route path="/hq-batches" component={requireAuth(composedWrapApp(HqBatchesPage))}/>
//                     <Route path="/hq-product-verify/:productId"
//                            component={requireAuth(composedWrapApp(HqProductVerify))}/>
//                     <Route path="/hq-gtin-delete" component={requireAuth(composedWrapApp(HqGtinDeletePage))}/>
//                     <Route path="/hq-gcp-delete" component={requireAuth(composedWrapApp(HqGcpDeletePage))}/>
//                     <Route path="/hq-gtin-send" component={requireAuth(composedWrapApp(HqGtinSend))}/>
//                     <Route path="/hq-gcp-send" component={requireAuth(composedWrapApp(HqGcpSend))}/>
//                     <Route path="/gcp-edit/:gcpId" component={requireAuth(composedWrapApp(GcpEditOne))}/>
//                     <Route path="/gcp-verify/:gcpId" component={requireAuth(composedWrapApp(GcpVerify))}/>
//
//                     <Route path="/products" component={requireAuth(composedWrapApp(ProductsPage))}/>
//                     <Route path="/products14" component={requireAuth(composedWrapApp(Products14Page))}/>
//                     <Route path="/products-update-cat" component={requireAuth(composedWrapApp(ProductsUpdateCat))}/>
//                     <Route path="/product/:productId" exact={true}
//                            component={requireAuth(composedWrapApp(ProductOneView))}/>
//                     <Route path="/product/:productId/edit" component={requireAuth(composedWrapApp(ProductOneEdit))}/>
//                     <Route path="/company-create" component={requireAuth(composedWrapApp(CompanyCreate))}/>
//                     <Route path="/company-self-create" component={composedCompanySelfWrap(CompanySelfCreate)}/>
//                     <Route path="/company-self-create-test" component={composedCompanySelfWrap(CompanySelfCreateTest)}/>
//                     <Route path="/company-test" component={composedCompanySelfWrap(Signup)}/>
//
//                     <Route path="/product8s" exact={true} component={requireAuth(composedWrapApp(Product8sPage))}/>
//                     <Route path="/product8s/create" component={requireAuth(composedWrapApp(Product8Create))}/>
//                     <Route path="/product8/:productId" exact={true}
//                            component={requireAuth(composedWrapApp(Product8View))}/>
//                     <Route path="/company/:companyId/product8-file-create" exact={true}
//                            component={requireAuth(composedWrapApp(Product8FileCreate))}/>
//                     <Route path="/product8/:productId/edit" component={requireAuth(composedWrapApp(Product8Edit))}/>
//
//                     <Route path="/product8-file/:fileId" exact={true}
//                            component={requireAuth(composedWrapApp(Product8FileEdit))}/>
//
//                     <Route path="/signup/completed" component={composedCompanySelfWrap(SignupCompleted)}/>
//                     <Route path="/signup" exact={true} component={composedCompanySelfWrap(CompanySelfCreate)}/>
//                     <Route path="/company/register/completed"
//                            component={composedCompanySelfWrap(CompanySelfCreateCompleted)}/>
//
//
//                     <Route path="/company/:id/gcp/:gcpId/barcodes"
//                            component={requireAuth(composedWrapApp(BarcodesPage))}/>
//                     <Route path="/company/:id/gcp/:gcpId/barcode-create"
//                            component={requireAuth(composedWrapApp(BarcodeCreate))}/>
//                     <Route path="/company/:id/gcp/:gcpId/barcode/:barcodeId"
//                            component={requireAuth(composedWrapApp(BarcodeEdit))}/>
//                     <Route path="/company/:id/gcp/:gcpId/payments"
//                            component={requireAuth(composedWrapApp(CompanyPayments))}/>
//                     <Route path="/company/:id/gcp/:gcpId/invoices"
//                            component={requireAuth(composedWrapApp(CompanyInvoices))}/>
//                     <Route path="/company/:id/gcp/:gcpId/invoice/:invoiceId"
//                            component={requireAuth(composedWrapApp(InvoiceView))}/>
//
//                     <Route path="/company/:id/gcp/:gcpId/:prefix/:size/product-create"
//                            component={requireAuth(composedWrapApp(ProductCreate))}/>
//
//                     <Route path="/company/:companyId/product8s"
//                            component={requireAuth(composedWrapApp(CompanyProduct8s))}/>
//                     <Route path="/company/:companyId/product8-files"
//                            component={requireAuth(composedWrapApp(CompanyProduct8Files))}/>
//
//                     <Route path="/company/:companyId"
//                            component={requireAuth(composedWrapApp(CompanyViewContainer))}/>
//                     <Route path="/products-edit" component={requireAuth(composedWrapApp(ProductsEditAllPage))}/>
//                     <Route path="/products-verify" component={requireAuth(composedWrapApp(ProductsEditVerifyAllPage))}/>
//
//                     <Route path="/signup-individual" component={composedCompanySelfWrap(SignupIndividual)}/>
//                     <Route path="/signup-company" component={composedCompanySelfWrap(SignupCompany)}/>
//
//
//                     <Route path="/product/:productId/images" exact={true}
//                            component={requireAuth(composedWrapApp(ProductImagesView))}/>
//
//                     <Route path="/settings" component={requireAuth(composedWrapApp(SettingsPage))}/>
//                     <Route path="/prod-appls" component={requireAuth(composedWrapApp(ProductApplications))}/>
//                     <Route path="/prod-image-appls" component={requireAuth(composedWrapApp(ProductImageApplications))}/>
//                     <Route path="/prod-appl/:applId" exact={true}
//                            component={requireAuth(composedWrapApp(ProductApplicationEdit))}/>
//                     <Route path="/prod-appl/deny/:applId" exact={true}
//                            component={requireAuth(composedWrapApp(ProductRequestNoteCreate))}/>
//
//                     <Route path="/prod-image-appl/deny/:applId" exact={true}
//                            component={requireAuth(composedWrapApp(ProductImageRequestNoteCreate))}/>
//
//                     <Route path="/prod-image-appl/:productId/:applId" exact={true}
//                            component={requireAuth(composedWrapApp(ProductImageApplicationEdit))}/>
//                     <Route path="/barcode-image-edit/:barcode" exact={true}
//                            component={requireAuth(composedWrapApp(ProductBarcodeImageView))}/>
//
//
//                     <Route path="/company-appls" component={requireAuth(composedWrapApp(CompanyApplications))}/>
//                     <Route path="/company-appl/:applId" exact={true}
//                            component={requireAuth(composedWrapApp(CompanyApplicationEdit))}/>
//                     <Route path="/sectors" component={requireAuth(composedWrapApp(SectorsPage))}/>
//                     <Route path="/sector-create" component={requireAuth(composedWrapApp(SectorCreate))}/>
//                     <Route path="/sector/:id/edit" exact={true} component={requireAuth(composedWrapApp(SectorEdit))}/>
//
//                     <Route path="/classifs" component={requireAuth(composedWrapApp(ProductClassificationConfig))}/>
//
//                     <Route path="/invoices" component={requireAuth(composedWrapApp(InvoicesPage))}/>
//                     <Route path="/fees" component={requireAuth(composedWrapApp(FeeConfig))}/>
//
//                     <Route path="/invoice-mails" component={requireAuth(composedWrapApp(InvoicesMailPage))}/>
//                     <Route path="/invoice-mail-items/:id"
//                            component={requireAuth(composedWrapApp(InvoicesMailItemsPage))}/>
//                     <Route path="/invoice-mail-template/create"
//                            component={requireAuth(composedWrapApp(InvoiceMailTemplateCreate))}/>
//                     <Route path="/invoice-mail-template/:id/edit"
//                            component={requireAuth(composedWrapApp(InvoiceMailTemplateEdit))}/>
//                     <Route path="/invoice-mail-template"
//                            component={requireAuth(composedWrapApp(InvoiceMailTemplateView))}/>
//                     <Route path="/login" component={LoginEmail}/>
//                     <Route path="/login-express" component={LoginExpress}/>
//                     <Route path="/waiting" component={Waiting}/>
//                     <Route path="/logintest" component={LoginEmailOld}/>
//                     <Route path="/admin-create" component={requireAuth(composedWrapApp(AdminUserCreate))}/>
//                     <Route path="/admin-pwd-change/:id"
//                            component={requireAuth(composedWrapApp(AdminUserPasswordChange))}/>
//
//                     <Route path="/sms-template-edit" component={requireAuth(composedWrapApp(SmsTemplateEdit))}/>
//                     <Route path="/sms-items" component={requireAuth(composedWrapApp(SmsItemsPage))}/>
//                     <Route path="/sent-emails" component={requireAuth(composedWrapApp(SentEmailsPage))}/>
//
//                     <Route path="/sms-mass-bodies" component={requireAuth(composedWrapApp(SmsMassBodiesPage))}/>
//                     <Route path="/sms-mass-body-create" component={requireAuth(composedWrapApp(SmsMassCreate))}/>
//                     <Route path="/sms-mass-body-edit/:id" component={requireAuth(composedWrapApp(SmsMassEdit))}/>
//                     <Route path="/sms-mass-body/:id" component={requireAuth(composedWrapApp(SmsMassItemsPage))}/>
//                     <Route path="/inquiries" component={requireAuth(composedWrapApp(InquiriesPage))}/>
//                 </Switch>
//             </Router>
//         </div>
//     </Provider>,
//     document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
