/**
 * Created by gantushig on 11/6/15.
 */

import {Link, matchPath, Route, Switch, useLocation, useParams, useRouteMatch} from "react-router-dom";
import React, {useEffect, useReducer} from "react";
import {fetchCompany} from "./actions/actions";
import dayjs from "dayjs";
import dayjsPluginUTC from 'dayjs-plugin-utc'
import {ActionTypes} from "./Constants";
import {css} from "@emotion/react";
import ClipLoader from "react-spinners/ClipLoader";
import CompanyViewComponent from "./CompanyViewComponent";
import CompanyEdit from "./CompanyEdit";
import CompanyContacts2 from "./CompanyContacts2";
import CompanyGcps from "./CompanyGcps";
import CompanyProducts from "./CompanyProducts";

dayjs.extend(dayjsPluginUTC)

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

const initialState = {
    tab:0,
    company: {},
    notes:[],
    loading: false,
    refresh:false
};

const reducer = (state, action) => {
    switch (action.type) {
        case ActionTypes.GET_COMPANY_REQUEST:
        case ActionTypes.CREATE_USER_REQUEST:
        case ActionTypes.UPDATE_USER_REQUEST:
        case ActionTypes.FETCH_CONTACT_REQUEST:
        case ActionTypes.FETCH_CONTACTS_REQUEST:
        case ActionTypes.UPDATE_COMPANY_REQUEST:
            return {...state, loading: true};
        case ActionTypes.GET_COMPANY_SUCCESS:{
            const company = { ...action.company };
            if (company.joined) company.joined = Date.parse(company.joined);
            if (company.established) company.established = Date.parse(company.established);
            return { ...state, company, notes: action.notes, loading: false };
        }
        case ActionTypes.CREATE_GCP_SUCCESS: {
            let company = {...state.company}
            let g = {...action.gcp}
            if (g.joined) {
                company.joined = Date.parse(g.joined);
            }
            return {
                ...state,
                company,
                loading: false
            }
        }
        case ActionTypes.UPDATE_COMPANY_SUCCESS: {
            let company = {...action.company}
            if (company.joined) {
                company.joined = Date.parse(company.joined);
            }
            if (company.established) {
                company.established = Date.parse(company.established);
            }
            return {
                ...state, company, loading: false
            }
        }

        case ActionTypes.FETCH_CONTACT_SUCCESS:
        case ActionTypes.FETCH_CONTACTS_SUCCESS:
        case ActionTypes.FETCH_GCPS_SUCCESS:
        case ActionTypes.FETCH_GCP_SUCCESS:
            return { ...state, loading: false };
        case 'REFRESH':
            return { ...state, refresh: !state.refresh };

        case 'CHANGE_FIELD':{
            const company = { ...state.company, [action.name]: action.value };
            if (action.name === "sector") company[action.name] = Number(action.value);
            return { ...state, company, loading: false };
        }

        default:
            return state;
    }
};




const CompanyViewContainer = () => {
    const {companyId} = useParams();
    let { path, url } = useRouteMatch();
    const {pathname}  = useLocation();
    const [state, dispatch] = useReducer(reducer, initialState);
    const {company, notes, loading, refresh} = state;

    useEffect(() => {
        fetchCompany(dispatch,  companyId);
    }, [companyId, refresh]);

    const handleUpdate = () => dispatch({ type: "REFRESH" });

    const getTabIndex = () => {
        if (matchPath(pathname, { path: `/company/:id/contacts` })) return 1;
        if (matchPath(pathname, { path: `/company/:id/gcps` })) return 2;
        if (matchPath(pathname, { path: `/company/:id/products` })) return 3;
        return 0;
    };

    const tab = getTabIndex();

    return (
        <div className="inner-container">
            <span className = 'block text-2xl mb-10 font-bold text-gray-800'>{company.name}</span>

            <div className="mb-5">
                <div className="tabs is-toggle is-fullwidth is-medium">
                    <ul>
                        <li className={tab === 0  ? 'is-active' : ''}>
                            <Link to={`${url}`}>Байгууллага</Link>
                        </li>
                        <li className={tab === 1  ? 'is-active' : ''}>
                            <Link to={`${url}/contacts`}>Хариуцсан ажилтан</Link>
                        </li>
                        <li className={tab === 2  ? 'is-active' : ''}>
                            <Link to={`${url}/gcps`}>GCP</Link>
                        </li>
                        <li className={tab === 3  ? 'is-active' : ''}>
                            <Link to={`${url}/products`}>Бүтээгдэхүүн</Link>
                        </li>
                    </ul>
                </div>
            </div>

            <ClipLoader color="#ffffff" loading={loading} css={override} size={150}/>
            <Switch>
                <Route exact path={path}>
                    <CompanyViewComponent company={company} notes = {notes} onUpdate={handleUpdate}/>
                </Route>
                <Route path={`${path}/edit`}>
                    <CompanyEdit
                        inCompany={company}
                        onUpdate={handleUpdate}/>
                </Route>
                <Route  path={`${path}/contacts`}>
                    <CompanyContacts2/>
                </Route>
                <Route  path={`${path}/gcps`}>
                    <CompanyGcps/>
                </Route>
                <Route  path={`${path}/products`}>
                    <CompanyProducts/>
                </Route>
            </Switch>
        </div>
    )

}


export default CompanyViewContainer;




